import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import OnboardingAdd from "./OnboardingAdd";
import OnboardingTable from "./OnboardingTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Onboarding() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="internetsub">
      <div className="main-content">
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: "white",
              },
            }}
          >
            <Tab
              label="Finish Onboarding"
              {...a11yProps(0)}
              sx={{ color: "white" }}
            />
            <Tab label="MC Shield" {...a11yProps(1)} sx={{ color: "white" }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OnboardingAdd />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OnboardingTable />
        </TabPanel>
      </div>
    </div>
  );
}

export default Onboarding;
