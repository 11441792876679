import React, { useState } from "react";
import Sidebar from "../components/sidebar";
import Modal from "react-bootstrap/Modal";
import DealerTable from "./dealertb";
import Form from "./Createform";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";

function Dealers() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="Clients">
      <div className="main">
        <div>
          <button onClick={handleShow} className="AddClient">
            <i className="fa fa-user-plus" aria-hidden="true"></i> Add Reseller
          </button>
          <NavLink
            exact
            to="/dealer/details/"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            More Details
          </NavLink>
          <NavLink
            exact
            to="/internet/dealers/accounting"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            Accounting
          </NavLink>
          <Modal show={show} onHide={handleClose} className="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>Add Client</Modal.Title>
            </Modal.Header>
            <Form parentFunction={handleClose} />
          </Modal>
        </div>
        <div className="testo">
          <DealerTable />
        </div>
      </div>
    </div>
  );
}

export default Dealers;
