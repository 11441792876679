import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import logo from "./logo.png";
import background from "./background.jpeg";
import "./login.css";

const LoginPage = () => {
  let { loginUser } = useContext(AuthContext);

  return (
    <div className="maino">
      <div className="left">
        <img src={background} alt="Logo" width={"100%"} height={"100%"} />
      </div>
      <div className="right">
        <div className="upper">
          <div className="texto">
            <h4>Welcome Back</h4>
          </div>
          <div className="im">
            <img src={logo} alt="Logo" width={"40%"} />
          </div>
        </div>
        <div className="lower">
          <h2>Login</h2>
          <div className="testd">
            <form onSubmit={loginUser}>
              <div className="testf">
                <input
                  type="text"
                  name="username"
                  placeholder="Enter Username"
                />
                <hr />
              </div>
              <div>
                <input
                  className="testf"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                />
                <hr />
              </div>
              <div>
                <button type="submit" className="testp">
                  Login
                </button>
              </div>
            </form>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
