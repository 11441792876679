import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = () => {
  const [series] = useState([
    {
      name: "Demo Data",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]);

  const [options] = useState({
    chart: {
      type: "line",
      height: 350,
      foreColor: "#ffffff",
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " value";
        },
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
