import React, { useState, useContext } from "react";
import { API_URL } from "../components/api";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import "bootstrap/dist/css/bootstrap.css";

function Shiftend(props) {
  const [message, setMessage] = useState(null);
  const [confirm, setConfirm] = useState(false); // State to manage confirmation

  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const handleConfirmation = () => {
    setConfirm(true); // Set confirmation to true
  };

  const handleCancel = () => {
    setConfirm(false); // Reset confirmation
  };

  const handleSubmit = async () => {
    Axios.post(
      `${API_URL}/inventory/shift/end/`,
      {
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Shift ended successfully!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Failed to end shift!", "danger"); // Show the error message
      });
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 1.2 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  return (
    <div className="container mt-5">
      <div className="card p-4 shadow">
        {confirm ? ( // Render confirmation dialog
          <div>
            <p>Are you sure you want to end the shift?</p>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirmation}
            >
              End Shift
            </button>
          </div>
        )}
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Shiftend;
