import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import Receipt from "./receipt";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";

function ResellerCollect(props) {
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [paidUSD, setPaidUSD] = useState(0);
  const [paidLBP, setPaidLBP] = useState(0);
  let [dealers, setdealers] = useState([]);
  const [dealer, setdealer] = useState("");

  const [returnedLBP, setReturnedLBP] = useState(0);
  const [returnedUSD, setReturnedUSD] = useState(0);
  const [authTokens] = useState(() =>
    JSON.parse(localStorage.getItem("authTokens"))
  );
  const { user } = useContext(AuthContext);
  const client_id = props.client_id;

  const [remainingBalance, setRemainingBalance] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const getdealers = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/dealers/info/paid/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setdealers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getdealers();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = dealers.map((item) => {
    return {
      label: item.fullname_en,
      value: item.dealer_id,
    };
  });

  const collectClose = () => props.handleClose(); // close the collect modal

  useEffect(() => {
    let totalPaidUSD = parseFloat(paidUSD);
    let totalPaidLBP = parseFloat(paidLBP) / parseFloat(exchangeRate);

    if (isNaN(totalPaidUSD)) totalPaidUSD = 0;
    if (isNaN(totalPaidLBP)) totalPaidLBP = 0;

    let totalReturnedUSD = parseFloat(returnedUSD);
    let totalReturnedLBP = parseFloat(returnedLBP) / parseFloat(exchangeRate);

    if (isNaN(totalReturnedUSD)) totalReturnedUSD = 0;
    if (isNaN(totalReturnedLBP)) totalReturnedLBP = 0;

    const totalPaid = totalPaidUSD + totalPaidLBP;
    const totalReturned = totalReturnedUSD + totalReturnedLBP;
    const remaining = totalPaid - totalReturned;

    setRemainingBalance(remaining); // Allow remaining balance to be negative
  }, [paidUSD, paidLBP, returnedUSD, returnedLBP, exchangeRate]);

  const handleExchangeRateChange = (event) => {
    setExchangeRate(event.target.value);
  };

  const handlePaidUSDChange = (event) => {
    setPaidUSD(event.target.value);
  };

  const handlePaidLBPChange = (event) => {
    setPaidLBP(event.target.value);
  };

  const handleReturnedLBPChange = (event) => {
    setReturnedLBP(event.target.value);
  };

  const handleReturnedUSDChange = (event) => {
    setReturnedUSD(event.target.value);
  };

  const handlePostRequest = async () => {
    try {
      const requestData = {
        dealer_id: dealer,
        currency: selectedCurrency,
        paid_usd: paidUSD,
        paid_lbp: paidLBP,
        returned_usd: returnedUSD,
        returned_lbp: returnedLBP,
        exchange_rate: exchangeRate,
        total_paid: remainingBalance,
        userdealer: user.username,
      };

      const response = await Axios.post(
        `${API_URL}/accounting/dealer/accounting/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      console.log(response);
      // Close the collect modal and show the receipt modal
      collectClose();
      props.closeDrawer();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="collect-container">
      <h5 className="collect-heading">Please enter the payment details</h5>
      <div className="collect-form">
        <label>
          Reseller:
          <Select
            className="search-line"
            required
            options={options}
            onChange={(opt) => setdealer(opt.value)}
          />
        </label>
        <label className="collect-label">
          Choose Currency:
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className="form-select collect-select"
          >
            <option value="USD">USD</option>
            <option value="LBP">LBP</option>
          </select>
        </label>
        {selectedCurrency === "LBP" && (
          <div className="convert-section">
            <label className="collect-label">
              Exchange Rate:
              <input
                type="number"
                step="0.01"
                min="0"
                value={exchangeRate}
                onChange={handleExchangeRateChange}
                className="form-control collect-input"
              />
            </label>
            <label className="collect-label">
              Paid Amount in LBP:
              <input
                type="number"
                step="0.01"
                min="0"
                value={paidLBP}
                onChange={handlePaidLBPChange}
                className="form-control collect-input darker-bg"
              />
            </label>
            <label className="collect-label">
              Returned Amount in LBP:
              <input
                type="number"
                step="0.01"
                min="0"
                value={returnedLBP}
                onChange={handleReturnedLBPChange}
                className="form-control collect-input darker-bg"
              />
            </label>
          </div>
        )}
        {selectedCurrency === "USD" && (
          <div>
            <label className="collect-label">
              Paid Amount in USD:
              <input
                type="number"
                step="0.01"
                min="0"
                value={paidUSD}
                onChange={handlePaidUSDChange}
                className="form-control collect-input darker-bg"
              />
            </label>
          </div>
        )}

        <label className="collect-label">
          Remaining Balance:
          <input
            type="text"
            value={remainingBalance.toFixed(2)}
            readOnly
            className="form-control collect-input darker-bg"
          />
        </label>

        <div className="collect-submit">
          <button onClick={handlePostRequest} className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResellerCollect;
