import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function Formp(props) {
  console.log(props.hi);
  let v = props.hi;
  let plan = props.plan;
  let up = props.up;
  let down = props.down;
  let monthl = props.monthly;
  let dail = props.daily;
  let cos = props.cost;
  let sell = props.sell;

  function refreshPage() {
    window.location.reload(false);
  }
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  const [planname, setname] = useState("");
  const [download, setdownload] = useState("");
  const [upload, setupload] = useState("");
  const [daily, setdaily] = useState("");
  const [monthly, setmonthly] = useState("");
  const [cost, setcost] = useState("");
  const [selling, setselling] = useState("");
  const [expiryDateDisabled, setExpiryDateDisabled] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getpermisions = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setExpiryDateDisabled(response.data === "Reseller");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getpermisions();

    return () => {
      isMounted = false;
    };
  }, []);
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/plans/internet`,
      {
        Action: "Put",
        plan_id: v,
        plan_name: planname,
        download_speed: download,
        upload_speed: upload,
        daily_limit: daily,
        monthly_limit: monthly,
        cost: cost,
        selling_price: selling,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Plan Name:
            <input
              type="text"
              className="form-control"
              value={planname}
              placeholder={plan}
              onChange={(e) => setname(e.target.value)}
              disabled={expiryDateDisabled}
            />
          </label>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Download Speed:
              <input
                type="number"
                min="0"
                className="form-control"
                value={download}
                placeholder={down}
                onChange={(e) => setdownload(e.target.value)}
                disabled={expiryDateDisabled}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Upload Speed:
              <input
                type="number"
                min="0"
                className="form-control"
                value={upload}
                placeholder={up}
                onChange={(e) => setupload(e.target.value)}
                disabled={expiryDateDisabled}
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Daily Limit:
              <input
                type="number"
                min="0"
                className="form-control"
                value={daily}
                placeholder={dail}
                onChange={(e) => setdaily(e.target.value)}
                disabled={expiryDateDisabled}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Monthly Limit:
              <input
                type="number"
                min="0"
                className="form-control"
                value={monthly}
                placeholder={monthl}
                onChange={(e) => setmonthly(e.target.value)}
                disabled={expiryDateDisabled}
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Cost:
              <input
                type="number"
                min="0"
                className="form-control"
                value={cost}
                placeholder={cos}
                onChange={(e) => setcost(e.target.value)}
                disabled={expiryDateDisabled}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Selling Price:
              <input
                type="number"
                min="0"
                className="form-control"
                value={selling}
                placeholder={sell}
                onChange={(e) => setselling(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formp;
