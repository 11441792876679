import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "../components/api";

import ReactApexChart from "react-apexcharts";
import axios from "axios";
import AuthContext from "../context/AuthContext";

const Chart = () => {
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [data, setData] = useState({
    starter: 0,
    basic: 0,
    light: 0,
    popular: 0,
    premium: 0,
    gold: 0,
    platinum: 0,
    diamond: 0,
  });

  const fetchData = (option) => {
    axios
      .get(`${API_URL}/radius/dashboard/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
          option: option,
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          [option]: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData("starter");
    fetchData("basic");
    fetchData("light");
    fetchData("popular");
    fetchData("premium");
    fetchData("gold");
    fetchData("platinum");
    fetchData("diamond");
  }, [authTokens.access, user.username]);

  useEffect(() => {
    updateSeries();
  }, [
    data.basic,
    data.light,
    data.popular,
    data.premium,
    data.gold,
    data.platinum,
    data.diamond,
  ]);

  const updateSeries = () => {
    setSeries([
      {
        name: "Subscriptions",
        data: [
          data.basic,
          data.light,
          data.popular,
          data.premium,
          data.gold,
          data.platinum,
          data.diamond,
        ],
      },
    ]);
  };

  const [series, setSeries] = useState([
    {
      name: "Subscriptions",
      data: [
        data.basic,
        data.light,
        data.popular,
        data.premium,
        data.gold,
        data.platinum,
        data.diamond,
      ],
    },
  ]);

  const [options] = useState({
    chart: {
      type: "bar",
      height: 350,
      foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Basic",
        "Light",
        "Popular",
        "Premium",
        "Gold",
        "Platinum",
        "Diamond",
      ],
    },
    yaxis: {
      title: {
        text: "Subscriptions",
      },
      grid: {
        drawBorder: false,
      },
    },
    fill: {
      opacity: 0.5,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " subscription/s";
        },
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default Chart;
