import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";

function AddProduct() {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("-");

  const validate = () => {
    return firstname !== "";
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      refreshPage();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/suppliers/`,
      {
        Action: "Post",
        firstname: firstname,
        lastname: lastname,
        phone_number: phone,
        company_name: company,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              First Name:
              <input
                className="form-control"
                type="text"
                value={firstname}
                required
                onChange={(e) => setfirstname(e.target.value)}
              />
            </label>

            <label>
              Last Name:
              <input
                className="form-control"
                type="text"
                value={lastname}
                required
                onChange={(e) => setlastname(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Phone Number:
              <input
                className="form-control"
                type="number"
                step="1"
                min="0"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
              />
            </label>

            <label>
              Company:
              <input
                className="form-control"
                type="text"
                value={company}
                required
                onChange={(e) => setcompany(e.target.value)}
              />
            </label>
          </div>
          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default AddProduct;
