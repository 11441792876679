import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "bootstrap/dist/css/bootstrap.min.css";
import ResetPassword from "./resetPassword";
import Modal from "react-bootstrap/Modal";
import Notification from "./SendNotification";
import AuthContext from "../context/AuthContext";
import axios from "axios";

const MobileUsers = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseNotification = () => setShowNotification(false);
  const handleShowNotification = () => setShowNotification(true);
  const [UserDetails, setUserDetails] = useState({ user_id: 0 });
  const updateUserDetails = (details) => setUserDetails(details);
  const deselectAllUsers = () => setSelectedUserIds([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/radius/subscriptions/mobile/users/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: { username: user.username },
          }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const handleCheckboxChange = (event, userId) => {
    if (event.target.checked) {
      setSelectedUserIds((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUserIds((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const columns = [
    {
      width: 10,
      renderCell: (params) => (
        <input
          type="checkbox"
          className="form-check-input"
          onChange={(event) =>
            handleCheckboxChange(event, params.row.oss_user_id)
          }
          checked={selectedUserIds.includes(params.row.oss_user_id)}
        />
      ),
    },
    {
      field: "fullname_en",
      headerName: "Client",
      flex: 2,
      minWidth: 220,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = { user_id: params.row.oss_user_id };
              updateUserDetails(details);
              handleShow();
            }}
          >
            Reset Password
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.oss_user_id;

  const handleSearch = (event) => setSearchQuery(event.target.value);

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-plan"
      />
      {selectedUserIds.length > 0 && (
        <button
          onClick={handleShowNotification}
          className="edit-client"
          style={{ marginTop: 10 }}
        >
          Send Notification
        </button>
      )}
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{ Toolbar: CustomToolbar }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <ResetPassword user_id={UserDetails.user_id} />
        </div>
      </Modal>
      <Modal show={showNotification} onHide={handleCloseNotification}>
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <Notification
            user_ids={selectedUserIds}
            deselectAllUsers={deselectAllUsers}
            closeModal={handleCloseNotification}
          />
        </div>
      </Modal>
    </div>
  );
};

export default MobileUsers;
