import React, { useState } from "react";
import "./sub.css";
import Formi from "./createsub";
import Modal from "react-bootstrap/Modal";
import SubInternet from "./subinternettable";

function Freesub() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="internetsub">
      <div className="main-content">
        <div className="add-subscription">
          <Modal show={show} onHide={handleClose} className="modal-lg">
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title>Add Subscription</Modal.Title>
              </Modal.Header>
              <Formi closeModal={handleClose} />
            </div>
          </Modal>
        </div>
        <SubInternet />
      </div>
    </div>
  );
}

export default Freesub;
