import React, { useState } from "react";
import "./sub.css";
import Formi from "./createsub";
import IssueAdd from "./issueAdd";

import Modal from "react-bootstrap/Modal";
import SubInternet from "./subinternettable";

function CommissionSub() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAddIssue, setShowAddIssue] = useState(false);
  const handleCloseIssue = () => setShowAddIssue(false);
  const handleShowIssue = () => setShowAddIssue(true);

  return (
    <div className="internetsub">
      <div className="main-content">
        <div className="add-subscription">
          <button onClick={handleShow} className="add-button">
            Add Subscription
          </button>
          <button onClick={handleShowIssue} className="add-button">
            Add Subscription Issue
          </button>
          <Modal show={show} onHide={handleClose} className="modal-lg">
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title>Add Subscription</Modal.Title>
              </Modal.Header>
              <Formi closeModal={handleClose} />
            </div>
          </Modal>
          <Modal show={showAddIssue} onHide={handleCloseIssue}>
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title>Add Subscription Issue</Modal.Title>
              </Modal.Header>
              <IssueAdd closeModal={handleCloseIssue} />
            </div>
          </Modal>
        </div>
        <SubInternet />
      </div>
    </div>
  );
}

export default CommissionSub;
