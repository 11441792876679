import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Formi(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [username, setusername] = useState("");
  const [site, setsite] = useState("-");
  const [subnotes, setnotes] = useState("-");

  const [due, setdue] = useState("First");
  const [consumption_option, setcon] = useState("LIMITED");
  const [connectivity, setcona] = useState("");
  const [expiration_date, setda] = useState("");
  let [plans, setplans] = useState([]);
  const [client, setclient] = useState("");
  const [plani, setplano] = useState("");
  const [suffix, setsuffix] = useState("-");

  let [notes, setcil] = useState([]);
  console.log(expiration_date);
  console.log(plani);
  console.log(client);
  const validate = () => {
    return (
      (username !== "") &
      (site !== "") &
      (connectivity !== "") &
      (client !== "") &
      (plani !== "")
    );
  };

  useEffect(() => {
    let isMounted = true;

    const getclients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setcil(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/resellers`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getclients();
    getplans();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = notes.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });

  const plano = plans.map((item) => {
    const planNameWithoutSM = item.plan_name.replace("SM-", "");
    return {
      label: planNameWithoutSM,
      value: item.internet_plan_id,
    };
  });
  const opta = [
    { value: "LIMITED", label: "LIMITED" },
    { value: "UNLIMITED", label: "UNLIMITED" },
  ];
  const cona = [
    { value: "Fiber", label: "Fiber" },
    { value: "Wireless", label: "Wireless" },
  ];
  const dua = [
    { value: "First", label: "First" },
    { value: "Half", label: "Half" },
  ];
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/`,
      {
        Action: "Post",
        username: username,
        client_id: client,
        plan_id: plani,
        consumption_option: consumption_option,
        connectivity: connectivity,
        expiry_date: expiration_date,
        site: site,
        due: due,
        suffix: suffix,
        notes: subnotes,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="name">
            <div className="form-group">
              <label>
                Username:
                <input
                  className="form-control"
                  type="text"
                  value={username}
                  required
                  placeholder="Username"
                  onChange={(e) => setusername(e.target.value)}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Client Name:
                <Select
                  className="search-line"
                  placeholder="Choose Client"
                  options={options}
                  onChange={(opt) => setclient(opt.value)}
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                Plan Name:
                <Select
                  className="search-line"
                  placeholder="Choose plan"
                  options={plano}
                  onChange={(opt) => setplano(opt.value)}
                />
              </label>
            </div>
          </div>

          <label>
            Notes:
            <input
              className="form-control"
              type="text"
              value={subnotes}
              required
              placeholder="Notes"
              onChange={(e) => setnotes(e.target.value)}
            />
          </label>

          <label>
            Connectivity Option:
            <Select
              className="search-line"
              placeholder="Choose Option"
              options={cona}
              onChange={(opt) => setcona(opt.value)}
            />
          </label>

          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Formi;
