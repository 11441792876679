import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { API_URL } from "../../components/api";
import "./dashboard.css";
import Chart from "./graph";
import LineChart from "./lineGraph";
import AuthContext from "../../context/AuthContext";

import axios from "axios";

function AccountingDashboard() {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [data, setData] = useState({
    online: 0,
    offline: 0,
    expired: 0,
    inactive: 0,
    hold: 0,
  });

  const fetchData = (option) => {
    axios
      .get(`${API_URL}/radius/dashboard/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
          option: option,
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          [option]: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPermissions = async () => {
    try {
      const response = await axios.get(`${API_URL}/administration/groups/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setGroup(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false once permissions are fetched
    }
  };
  const Subscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", {
      myProp: 0,
    });
  };
  const inactiveSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 5 });
  };
  const offlineSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 2 });
  };
  const onlineSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 1 });
  };
  const expiredSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 3 });
  };
  const holdSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 4 });
  };
  useEffect(() => {
    fetchData("online");
    fetchData("offline");
    fetchData("expired");
    fetchData("inactive");
    fetchData("hold");
    getPermissions();
  }, [authTokens.access, user.username]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Dashboard">
      <div className="top-cards">
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Collection</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>4793$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Invoices</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>423$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Remaining</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>2132$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Free Accounts</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>1813$</h4>
          </div>
        </div>
      </div>
      <div className="top-cards">
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Collection Office</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>2000$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Remaining Office</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>4990$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Collection Imad</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>2111$</h4>
          </div>
        </div>
        <div className="card1-accounting">
          <div className="card-top-accounting">
            <h4>Remaining Imad</h4>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>3000$</h4>
          </div>
        </div>
      </div>
      <div className="charts">
        <LineChart />
        <Chart />
      </div>
    </div>
  );
}

export default AccountingDashboard;
