import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../context/AuthContext";

function RequestHistory(props) {
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [cat, setcat] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getorders = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/requests/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            action: "History",
          },
        });

        if (response.status === 200 && isMounted) {
          // Convert upload and download to GB and limit decimals to 3

          setcat(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getorders();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="testii">
      <div className="mod">
        <table className="table">
          <thead>
            <tr>
              <th>Receiver</th>
              <th>Description</th>
              <th>Type</th>
              <th>Response</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.receiver}</td>
                <td>{item.description}</td>
                <td>{item.type}</td>
                <td>{item.response}</td>
                <td>{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RequestHistory;
