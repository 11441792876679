import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import { API_URL } from "../../components/api";
import EditOrder from "./OrderEdit";
import InvoiceOrder from "./OrderInvoice";
import QuotationOrder from "./OrderQuotation";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

//For API Requests
import axios from "axios";

function OrderTable() {
  const [data, setData] = useState([]);
  const [showdetails, setShowDetails] = useState(false);
  const [showQuotation, setShowQuotation] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const handleshowDetails = () => setShowDetails(true);
  const handlecloseDetails = () => setShowDetails(false);
  const handleshowQuotations = () => setShowQuotation(true);
  const handlecloseQuotations = () => setShowQuotation(false);
  const handleshowInvoices = () => setShowInvoice(true);
  const handlecloseInvoices = () => setShowInvoice(false);
  const [message, setMessage] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);

  const [DealerDetails, setDealerDetails] = useState({
    order_id: 0,
    fullname_en: "",
    client_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  const issues = (details) => {
    setDealerDetails(details);
    history.push("/accounting/order/edit", { myProp: details.order_id });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/order/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data.reverse());
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 50 },
    { field: "date", headerName: "Date", flex: 2, maxWidth: 100 },

    {
      field: "actions",
      headerName: "Actions",
      minwidth: 350,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                fullname_en: params.row.fullname_en,
              };
              updateDealerDetails(details);
              handleshowDetails();
            }}
          >
            Details
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                fullname_en: params.row.fullname_en,
              };
              updateDealerDetails(details);
              handleshowQuotations();
            }}
          >
            Quotation
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                fullname_en: params.row.fullname_en,
                client_id: params.row.client_id,
              };
              updateDealerDetails(details);
              handleshowInvoices();
            }}
          >
            Invoice
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                fullname_en: params.row.fullname_en,
              };
              updateDealerDetails(details);
              issues(details);
            }}
          >
            Update
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.order_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal
        show={showdetails}
        onHide={handlecloseDetails}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <EditOrder order_id={DealerDetails.order_id} />
      </Modal>
      <Modal
        show={showQuotation}
        onHide={handlecloseQuotations}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Quotation for {DealerDetails.fullname_en}</Modal.Title>
        </Modal.Header>
        <QuotationOrder order_id={DealerDetails.order_id} />
      </Modal>
      <Modal
        show={showInvoice}
        onHide={handlecloseInvoices}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice for {DealerDetails.fullname_en}</Modal.Title>
        </Modal.Header>
        <InvoiceOrder
          order_id={DealerDetails.order_id}
          client_id={DealerDetails.client_id}
        />
      </Modal>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default OrderTable;
