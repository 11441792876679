import React, { useContext, useState } from "react";
import { API_URL } from "../components/api";
import { styled, alpha } from "@mui/material/styles";
import AuthContext from "../context/AuthContext";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Axios from "axios";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      /* Styling for MenuItem on hover */
      "&:hover": {
        color: "black",
      },
    },
  },
}));

export default function CustomizedActions(props) {
  function refreshPage() {
    window.location.reload(false);
  }
  const [message, setMessage] = useState(null);

  let v = props.subscriptions;
  console.log(v);
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Action, setAction] = React.useState("");
  const [reason, setReason] = useState("");

  const [openy, setOpeny] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendAction = (id) => {
    setAction(id);
  };
  console.log(Action);
  const handleClickOpenpop = () => {
    setOpeny(true);
  };

  const handleClosepop = () => {
    setOpeny(false);
  };
  let SubmitAction = async (e) => {
    Axios.post(
      `${API_URL}/radius/subscriptions/reselleractions/`,
      {
        Action: Action,
        sub_id: v,
        reason: reason, // Pass the reason to the backend
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        //refreshPage();
        props.deselect();
        showMessage("Action successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Action failed!", "danger"); // Show the error message
      });
  };
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };
  return (
    <div className="test">
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        style={{
          backgroundColor: "transparent",
          height: "18px",
          padding: "10px",
        }}
        disableElevation
        onClick={handleClick}
      >
        <i className="fas fa-bolt"> </i> Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Recharge");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Recharge
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Activate");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Activate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Deactivate");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Deactivate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Reset");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Reset Quota
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Disconnect");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Disconnect
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Hold");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Hold
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Generate");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Generate Token
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Resetm");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Reset Mac
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            sendAction("Delete");
            handleClickOpenpop();
          }}
          disableRipple
        >
          Delete
        </MenuItem>
      </StyledMenu>
      <Dialog
        open={openy}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to proceed?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClosepop}>No</Button>
          <Button
            onClick={() => {
              handleClosepop();
              SubmitAction();
            }}
            autoFocus
          >
            yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}
