import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";

import Print from "./Print";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import "bootstrap/dist/css/bootstrap.min.css";

function PrintReceipt(props) {
  const [showReceipt, setShowReceipt] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState("");
  const [discount, setDiscount] = useState(0);
  const [selectedItem, setSelectedItem] = useState("Office");
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [balanceLBP, setBalanceLBP] = useState(0);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [paid, setPaid] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [total, setTotal] = useState(0);

  const [authTokens] = useState(() =>
    JSON.parse(localStorage.getItem("authTokens"))
  );
  const { user } = useContext(AuthContext);

  const receiptClose = () => setShowReceipt(false);
  const receiptShow = () => setShowReceipt(true);

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleExchangeRateChange = (event) => {
    setExchangeRate(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);

    // Search for the selected client in the clients array
    const selectedClientId = selectedOption.value;
    const selectedClient = clients.find(
      (client) => client.client_id === selectedClientId
    );

    // Now, you can access the details of the selected client and update your state variables
    if (selectedClient) {
      setBalanceLBP(selectedClient.balance_lbp);
      setBalanceUSD(selectedClient.balance_usd);
      console.log(selectedClient);
    }
  };

  const handleItemChange = (event) => {
    setSelectedItem(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const getClients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setClients(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getClients();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = clients.map((item) => ({
    label: item.fullname_en,
    value: item.client_id,
  }));

  useEffect(() => {
    if (selectedCurrency === "LBP" && exchangeRate !== "") {
      const converted = parseFloat(amount) / parseFloat(exchangeRate);
      setConvertedAmount(converted.toFixed(2));
    } else {
      setConvertedAmount("");
    }
  }, [selectedCurrency, exchangeRate, amount]);

  useEffect(() => {
    // Calculate total based on the paid amount and discount
    setTotal(parseFloat(paid) + parseFloat(discount));
  }, [paid, discount]);

  useEffect(() => {
    // Calculate remaining based on the balance and total
    if (selectedCurrency === "USD") {
      setRemaining(parseFloat(balanceUSD) - parseFloat(total));
    } else {
      setRemaining(parseFloat(balanceLBP) - parseFloat(total));
    }
  }, [balanceLBP, balanceUSD, total, selectedCurrency]);

  return (
    <div className="collect-container">
      <h5 className="collect-heading">Please enter the paid amount</h5>
      <div className="collect-form">
        <div className="form-group">
          <label>
            Client Name:
            <Select
              className="search-line"
              placeholder="Choose Client"
              options={options}
              onChange={handleClientChange}
              value={client}
            />
          </label>
        </div>
        <label className="collect-label">
          Balance LBP:
          <input
            type="number"
            value={balanceLBP}
            className="form-control collect-input darker-bg"
            readOnly
          />
        </label>
        <label className="collect-label">
          Balance USD:
          <input
            type="number"
            value={balanceUSD}
            className="form-control collect-input darker-bg"
            readOnly
          />
        </label>
        <label className="collect-label">
          Choose Currency:
          <select
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            className="form-select collect-select"
          >
            <option value="USD">USD</option>
            <option value="LBP">LBP</option>
          </select>
        </label>
        {selectedCurrency === "LBP" && (
          <div className="convert-section">
            <label className="collect-label">
              Exchange Rate:
              <input
                type="number"
                value={exchangeRate}
                onChange={handleExchangeRateChange}
                className="form-control collect-input"
              />
            </label>
            <label className="collect-label">
              Amount in LBP:
              <input
                type="number"
                value={amount}
                onChange={handleAmountChange}
                className="form-control collect-input darker-bg"
              />
            </label>
            <label className="collect-label">
              Discount in LBP:
              <input
                type="number"
                value={discount}
                onChange={(event) => setDiscount(event.target.value)}
                className="form-control collect-input darker-bg"
              />
            </label>
            {convertedAmount && (
              <p className="converted-amount">
                Amount in USD: {convertedAmount}
              </p>
            )}
          </div>
        )}
        {selectedCurrency === "USD" && (
          <label className="collect-label">
            Amount in USD:
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              className="form-control collect-input darker-bg"
            />
          </label>
        )}
        <label className="collect-label">
          Discount:
          <input
            type="number"
            value={discount}
            onChange={(event) => setDiscount(event.target.value)}
            className="form-control collect-input"
          />
        </label>
        <label className="collect-label">
          Paid:
          <input
            type="number"
            value={paid}
            onChange={(event) => setPaid(event.target.value)}
            className="form-control collect-input"
          />
        </label>
        <label className="collect-label">
          Remaining:
          <input
            type="number"
            value={remaining}
            className="form-control collect-input"
            readOnly
          />
        </label>
        <div className="collect-submit" onClick={receiptShow}>
          <button className="btn btn-primary">Submit</button>
        </div>
      </div>
      <Modal show={showReceipt} onHide={receiptClose}>
        <div className="receipt-modal">
          <Modal.Header closeButton>
            <Modal.Title>Receipt</Modal.Title>
          </Modal.Header>
          <Print
            clientName={client ? client.label : ""}
            balanceUSD={balanceUSD}
            balanceLBP={balanceLBP}
            paid={paid}
            remaining={remaining}
            discount={discount}
          />
        </div>
      </Modal>
    </div>
  );
}

export default PrintReceipt;
