import React, { useState, useEffect, useContext } from "react";
import DrawerTable from "./DrawerTable";

function Drawer() {
  return (
    <div className="Plans">
      <div className="main">
        <DrawerTable />
      </div>
    </div>
  );
}

export default Drawer;
