import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";

function PocketExchange() {
  const { user } = useContext(AuthContext);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("USD");
  const [action, setAction] = useState("withdraw");

  const [balanceDrawerUSD, setBalanceDrawerUSD] = useState(0);
  const [balanceDrawerLBP, setBalanceDrawerLBP] = useState(0);
  const [balancePocketMoneyUSD, setBalancePocketMoneyUSD] = useState(0);
  const [balancePocketMoneyLBP, setBalancePocketMoneyLBP] = useState(0);
  const [drawerId, setDrawerId] = useState(0);
  const [drawerName, setDrawerName] = useState("");
  const [employeeDrawers, setEmployeeDrawers] = useState([]);
  const [selectedDrawer, setSelectedDrawer] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/inventory/pocket/exchange/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          const initialDrawer = data.employee_drawers[0] || {};

          setBalanceDrawerUSD(data.latest_shift_balance.balance_usd);
          setBalanceDrawerLBP(data.latest_shift_balance.balance_lbp);
          setDrawerId(data.latest_shift_balance.drawer_id);
          setEmployeeDrawers(data.employee_drawers);
          setSelectedDrawer(initialDrawer.drawer_id || null);
          setDrawerName(initialDrawer.drawer_name || "");

          // Initialize pocket money balances once
          setBalancePocketMoneyUSD(data.drawer_1_balance.balance_usd);
          setBalancePocketMoneyLBP(data.drawer_1_balance.balance_lbp);

          setErrorMessage("");
          setSuccessMessage("");
          console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.username]);

  useEffect(() => {
    if (selectedDrawer !== null) {
      const selected = employeeDrawers.find(
        (drawer) => drawer.drawer_id === selectedDrawer
      );

      if (selected) {
        setBalanceDrawerUSD(selected.balance_usd);
        setBalanceDrawerLBP(selected.balance_lbp);
        setDrawerName(selected.drawer_name);
      }
    }
  }, [selectedDrawer, employeeDrawers]);

  const handleTransfer = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/inventory/pocket/exchange/`,
        {
          username: user.username,
          amount: amount,
          currency: currency,
          action: action,
          drawer_id: selectedDrawer,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setBalanceDrawerUSD(data.latest_shift_balance.balance_usd);
        setBalanceDrawerLBP(data.latest_shift_balance.balance_lbp);
        setDrawerId(data.latest_shift_balance.drawer_id);
        setDrawerName(data.latest_shift_balance.drawer_name);
        setErrorMessage("");
        setSuccessMessage("Transfer successful!");
      } else {
        setErrorMessage("Not sufficient account balance.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Not sufficient account balance.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Pocket Money Exchange</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Row>
        <Col md={6}>
          <Form>
            <Form.Group as={Row} controlId="formAction">
              <Form.Label column sm="4">
                Action
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                >
                  <option value="withdraw">Withdraw from Pocket Money</option>
                  <option value="deposit">Deposit to Pocket Money</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCurrency">
              <Form.Label column sm="4">
                Currency
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value="USD">USD</option>
                  <option value="LBP">LBP</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDrawer">
              <Form.Label column sm="4">
                Drawer
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  value={selectedDrawer || ""}
                  onChange={(e) => {
                    const selected = employeeDrawers.find(
                      (drawer) => drawer.drawer_id === parseInt(e.target.value)
                    );
                    setSelectedDrawer(selected?.drawer_id || null);
                  }}
                >
                  {employeeDrawers.map((drawer) => (
                    <option key={drawer.drawer_id} value={drawer.drawer_id}>
                      {drawer.drawer_name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAmount">
              <Form.Label column sm="4">
                Amount
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Button
              variant="primary"
              onClick={handleTransfer}
              style={{ margin: "10px" }}
            >
              Transfer
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <div className="mt-4">
            <h3>Current Balances</h3>
            <div>
              {drawerName ? (
                <>
                  <p>
                    {drawerName} - USD: {balanceDrawerUSD}
                  </p>
                  <p>
                    {drawerName} - LBP: {balanceDrawerLBP}
                  </p>
                </>
              ) : (
                <p>Select a drawer to see the balance</p>
              )}
              <p>Pocket Money - USD: {balancePocketMoneyUSD}</p>
              <p>Pocket Money - LBP: {balancePocketMoneyLBP}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PocketExchange;
