import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function bytesToGB(bytes) {
  return (bytes / 1000000000).toFixed(2); // Convert bytes to GB and round to 2 decimal places
}

function BootstrapProgressBar(props) {
  const maxGB = parseInt(bytesToGB(props.max)); // Convert maxGB to an integer
  const downloadGB = bytesToGB(props.download);
  const uploadGB = bytesToGB(props.upload);
  const usageGB = parseFloat(
    (parseFloat(downloadGB) + parseFloat(uploadGB)).toFixed(2)
  );

  const percentage = (usageGB / maxGB) * 100;

  const getColor = () => {
    if (percentage >= 80) {
      return "#dc3545"; // Red color for danger
    } else if (percentage >= 50) {
      return "#ffc107"; // Yellow color for warning
    } else {
      return "#007bff"; // Blue color for primary
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="percentage-tooltip" {...props}>
      {`${usageGB} GB / ${maxGB} GB`}
    </Tooltip>
  );

  return (
    <div>
      <div>{props.planName}</div>
      <OverlayTrigger
        placement="bottom"
        overlay={renderTooltip}
        delay={{ show: 250, hide: 400 }}
      >
        <ProgressBar
          variant=""
          now={percentage} // Use the percentage value here
          max={100}
          style={{
            width: "100px", // Adjust the width as needed
            height: "14px", // Adjust height
            cursor: "pointer",
            backgroundColor: "#a9a9a9", // Light Grey background color
            position: "relative", // Set position relative for tooltip placement
            borderRadius: "2px", // Adjust border radius
          }}
        >
          <ProgressBar
            variant=""
            now={percentage} // Fill the bar according to the percentage
            style={{
              backgroundColor: getColor(), // Apply color based on percentage
              borderRadius: "2px", // Adjust border radius
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#fff", // Set color to white
              fontSize: "10px", // Adjust font size
            }}
          >
            {`${usageGB} GB / ${maxGB} GB`}
          </div>
        </ProgressBar>
      </OverlayTrigger>
    </div>
  );
}

export default BootstrapProgressBar;
