import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../../components/api";
import EditOrder from "./QuotationsDetail";
import EditQuotation from "./EditQuotation";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

//For API Requests
import axios from "axios";

function QuotationTable() {
  const [data, setData] = useState([]);
  const [showdetails, setShowDetails] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [quotation, setQuotation] = useState("");

  const [loading, setLoading] = useState(true);

  const [showStatusPrompt, setShowStatusPrompt] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const [message, setMessage] = useState(null);

  const handleshowDetails = () => setShowDetails(true);
  const handlecloseDetails = () => setShowDetails(false);

  const handleshowEdit = () => setShowEdit(true);
  const handlecloseEdit = () => setShowEdit(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    order_id: 0,
    quotation_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/quotations/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data.reverse());
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 50,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleStatusCellClick(params)}
        >
          {params.value}
        </div>
      ),
    },
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 50 },
    { field: "date", headerName: "Date", flex: 2, minWidth: 50 },

    { field: "total_amount", headerName: "Total", flex: 2, minWidth: 50 },
    { field: "discount", headerName: "Discount", flex: 2, minWidth: 50 },
    {
      field: "totalMinusDiscount",
      headerName: "Total - Discount",
      flex: 2,
      minWidth: 50,
      valueGetter: (params) => {
        // Assuming total_amount and discount are numeric fields
        const total = params.row.total_amount || 0;
        const discount = params.row.discount || 0;
        return total - discount;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minwidth: 100,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                quotation_id: params.row.quotation_id,
              };
              updateDealerDetails(details);
              handleshowDetails();
            }}
          >
            Details
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                quotation_id: params.row.quotation_id,
              };
              updateDealerDetails(details);
              handleshowEdit();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.quotation_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };
  const handleStatusCellClick = (params) => {
    if (params.row.status === "Pending") {
      setNewStatus("Done");
      setSelectedRow(params.row);
      setQuotation(params.row.quotation_id); // Set the quotation ID when the status cell is clicked
      setShowStatusPrompt(true);
    } else {
      setNewStatus(params.row.status || "Done"); // Set default value if status is not present
      setShowStatusPrompt(true);
      setSelectedRow(params.row);
      setQuotation(params.row.quotation_id); // Set the quotation ID when the status cell is clicked
    }
  };

  const handleStatusPromptClose = () => {
    setNewStatus("");
    setShowStatusPrompt(false);
    setSelectedRow(null);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/quotations/status/`,
        {
          status: newStatus,
          quotation: quotation,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      console.log(response);
      showMessage("Status changed!", "success");
      // Fetch updated data
      const updatedData = await axios.get(`${API_URL}/inventory/quotations/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });
      setData(updatedData.data.reverse());
    } catch (error) {
      console.log(error);
      showMessage("Edit failed!", "danger");
    }
  };
  const handleStatusConfirmation = () => {
    console.log("Invoice ID:", selectedRow.quotation_id);
    console.log("New Status:", newStatus);

    // Perform any additional actions here (e.g., API call, state update)
    handleSubmit();

    handleStatusPromptClose();
  };
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal
        show={showdetails}
        onHide={handlecloseDetails}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <EditOrder quotation_id={DealerDetails.quotation_id} />
      </Modal>
      <Modal show={showedit} onHide={handlecloseEdit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <EditQuotation
          order_id={DealerDetails.order_id}
          quotation_id={DealerDetails.quotation_id}
        />
      </Modal>
      <Modal show={showStatusPrompt} onHide={handleStatusPromptClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="newStatus">Select new status:</label>
          <select
            id="newStatus"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="form-select" // Add Bootstrap class for styling
          >
            <option value="Done">Done</option>
            <option value="Blocked">Blocked</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleStatusPromptClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleStatusConfirmation}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuotationTable;
