import React, { useState, useEffect, useContext } from "react";
import FiberCoresTable from "./FiberCoresTable";

function FiberCores() {
  return (
    <div className="Plans">
      <div className="main">
        <FiberCoresTable />
      </div>
    </div>
  );
}

export default FiberCores;
