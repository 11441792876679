import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Issues from "./issues";
import "./History.css";
import ConsumptionHistory from "./consumptionHistory";
import IssueAdd from "./issueAdd";
import Modal from "react-bootstrap/Modal";

const History = () => {
  const location = useLocation();
  const test = location.state.myProp;

  // State to manage active tab
  const [activeTab, setActiveTab] = useState("tab1");
  const [showAddIssue, setShowAddIssue] = useState(false);
  const handleCloseIssue = () => setShowAddIssue(false);
  const handleShowIssue = () => setShowAddIssue(true);
  return (
    <div>
      <button onClick={handleShowIssue} className="add-button">
        Add Subscription Issue
      </button>
      <Modal show={showAddIssue} onHide={handleCloseIssue}>
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Add Subscription Issue</Modal.Title>
          </Modal.Header>
          <IssueAdd closeModal={handleCloseIssue} sub={test} />
        </div>
      </Modal>
      {/* Bootstrap tabs */}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "tab1" && "active"}`}
            id="tab1-tab"
            data-bs-toggle="tab"
            data-bs-target="#tab1"
            type="button"
            role="tab"
            aria-controls="tab1"
            aria-selected={activeTab === "tab1"}
            onClick={() => setActiveTab("tab1")}
          >
            Issues
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "tab2" && "active"}`}
            id="tab2-tab"
            data-bs-toggle="tab"
            data-bs-target="#tab2"
            type="button"
            role="tab"
            aria-controls="tab2"
            aria-selected={activeTab === "tab2"}
            onClick={() => setActiveTab("tab2")}
          >
            Consumption
          </button>
        </li>
      </ul>

      {/* Tab content */}
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade ${activeTab === "tab1" && "show active"}`}
          id="tab1"
          role="tabpanel"
          aria-labelledby="tab1-tab"
        >
          <Issues sub={test} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === "tab2" && "show active"}`}
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab2-tab"
        >
          <ConsumptionHistory sub={test} />
        </div>
      </div>
    </div>
  );
};

export default History;
