import React, { useState, useEffect, useContext } from "react";
import ActionTable from "./ActionLogTable";

function ActionLog() {
  return (
    <div className="Plans">
      <div className="main">
        <ActionTable />
      </div>
    </div>
  );
}

export default ActionLog;
