import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";

function EditOrder(props) {
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [cat, setcat] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getorders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/quotation/details/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              quotation_id: props.quotation_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setcat(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getorders();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="testi">
      <div className="mod">
        <table className="table">
          <thead>
            <tr>
              <th>Item Type</th>
              <th>Item Name</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.item_type}</td>
                <td>
                  {item.item_type === "Product"
                    ? item.product_name
                    : item.item_type === "Service"
                    ? item.service_name
                    : ""}
                </td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EditOrder;
