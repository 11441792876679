import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import EditProduct from "./ProductEdit";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

//For API Requests
import axios from "axios";

function ProductTable() {
  const [data, setData] = useState([]);
  const [group, setGroup] = useState("");
  console.log(group);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    product_id: 0,
    code: "",
    description: "",
    barcode: "",
    name: "",
    price: 0,
    cost: 0,
    category: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/products/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchGroups = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setGroup(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchGroups();
  }, [user.username]);

  const columns = [
    { field: "name", headerName: "Name", flex: 2, minWidth: 50 },
    { field: "description", headerName: "Description", flex: 2, minWidth: 50 },
    { field: "code", headerName: "Code", flex: 2, minWidth: 50 },
    { field: "barcode", headerName: "Barcode", flex: 2, minWidth: 50 },
    { field: "category_name", headerName: "Category", flex: 2, minWidth: 50 },

    ...(group === "yes"
      ? [
          {
            field: "cost",
            headerName: "Cost",
            flex: 1,
            minWidth: 60,
            valueFormatter: (params) => {
              // Format the value by adding a dollar sign
              return `$ ${params.value}`;
            },
          },
        ]
      : []),
    {
      field: "selling_price",
      headerName: "Price",
      flex: 1,
      minWidth: 60,
      valueFormatter: (params) => {
        // Format the value by adding a dollar sign
        return `$ ${params.value}`;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minwidth: 100,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                product_id: params.row.product_id,
                description: params.row.description,
                code: params.row.code,
                name: params.row.name,
                barcode: params.row.barcode,
                price: params.row.selling_price,
                cost: params.row.cost,
                category: params.row.category_id,
                category_name: params.row.category_name,
              };
              updateDealerDetails(details);
              handleshowo();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.product_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Products</Modal.Title>
        </Modal.Header>
        <EditProduct
          product_id={DealerDetails.product_id}
          name={DealerDetails.name}
          code={DealerDetails.code}
          description={DealerDetails.description}
          barcode={DealerDetails.barcode}
          cost={DealerDetails.cost}
          price={DealerDetails.price}
          category={DealerDetails.category}
          category_name={DealerDetails.category_name}
        />
      </Modal>
    </div>
  );
}

export default ProductTable;
