import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import SubInternet from "./SubscriptionTab";
import ClientTable from "./ClientsTab";
import TransactionsTable from "./TransactionTab";
import DashboardTab from "./DashboardTab";
import "./dealerdetails.css";

function DealerDetailsFree() {
  const [selectedOption, setSelectedOption] = useState(3);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/info/free/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  useEffect(() => {
    // Add logic here to handle the change of the selected option value
    // You can perform any additional actions or API calls based on the selectedOption value
    console.log("Selected Option value changed:", selectedOption);
  }, [selectedOption]);

  const options = data.map((item) => ({
    label: item.fullname_en,
    value: item.dealer_id,
  }));

  const handleSelectChange = (selected) => {
    setSelectedOption(selected?.value); // Store only the value
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "dashboard":
        return (
          <DashboardTab key={selectedOption} selectedOption={selectedOption} />
        );
      case "subscriptions":
        return (
          <SubInternet key={selectedOption} selectedOption={selectedOption} />
        );
      case "clients":
        return (
          <ClientTable key={selectedOption} selectedOption={selectedOption} />
        );
      case "transactions":
        return (
          <TransactionsTable
            key={selectedOption}
            selectedOption={selectedOption}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="Clients">
      <div className="main">
        <Select
          value={options.find((option) => option.value === selectedOption)}
          onChange={handleSelectChange}
          options={options}
          styles={{
            control: (provided) => ({
              ...provided,
              margin: "10px", // Add margin to the selector
            }),
          }}
        />

        <ul className="nav nav-tabs mt-3">
          <li className="nav-item">
            <a
              className={`nav-link ${
                selectedTab === "dashboard" ? "active" : ""
              }`}
              onClick={() => handleTabChange("dashboard")}
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              Dashboard
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                selectedTab === "subscriptions" ? "active" : ""
              }`}
              onClick={() => handleTabChange("subscriptions")}
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              Subscriptions
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                selectedTab === "clients" ? "active" : ""
              }`}
              onClick={() => handleTabChange("clients")}
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              Clients
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                selectedTab === "transactions" ? "active" : ""
              }`}
              onClick={() => handleTabChange("transactions")}
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              Transactions
            </a>
          </li>
        </ul>

        <div className="tab-content mt-3">{renderTabContent()}</div>
      </div>
    </div>
  );
}

export default DealerDetailsFree;
