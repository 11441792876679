import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function BootstrapProgressBar(props) {
  const percentage = Math.min(props.percentage, 100); // Ensure the percentage is capped at 100

  const getColor = () => {
    if (percentage >= 80) {
      return "danger";
    } else if (percentage >= 50) {
      return "warning";
    } else {
      return "primary";
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="percentage-tooltip" {...props}>
      {`${percentage}%`}
    </Tooltip>
  );

  return (
    <div>
      <div>{props.planName}</div>
      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <ProgressBar
          variant={getColor()}
          now={percentage}
          style={{
            width: "100px", // Adjust the width as needed
            borderRadius: "2px", // Adjust border radius
            height: "7px", // Adjust height
            cursor: "pointer",
            backgroundColor: "#a9a9a9", // Light Grey background color
          }}
        />
      </OverlayTrigger>
    </div>
  );
}

export default BootstrapProgressBar;
