import React, { useState, useContext } from "react";
import { API_URL } from "../components/api";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
function Formp(props) {
  function refreshPage() {
    //window.location.reload(false);
  }
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [planname, setname] = useState("");
  const [download, setdownload] = useState("");
  const [upload, setupload] = useState("");
  const [daily, setdaily] = useState("");
  const [monthly, setmonthly] = useState("");
  const [cost, setcost] = useState("");
  const [selling, setselling] = useState("");
  console.log(user.username);
  const validate = () => {
    return (
      (planname !== "") &
      (download !== "") &
      (upload !== "") &
      (daily !== "") &
      (monthly !== "") &
      (selling !== "") &
      (cost !== "")
    );
  };
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/plans/internet`,
      {
        Action: "Post",
        plan_name: planname,
        download_speed: download,
        upload_speed: upload,
        daily_limit: daily,
        monthly_limit: monthly,
        cost: cost,
        selling_price: selling,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    refreshPage();
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Plan Name:
            <input
              type="text"
              className="form-control"
              value={planname}
              required
              placeholder="Plan Name"
              onChange={(e) => setname(e.target.value)}
            />
          </label>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Download Speed:
              <input
                type="number"
                className="form-control"
                value={download}
                required
                placeholder="Download Speed"
                onChange={(e) => setdownload(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Upload Speed:
              <input
                type="number"
                className="form-control"
                placeholder="Upload Speed"
                value={upload}
                required
                onChange={(e) => setupload(e.target.value)}
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Daily Limit:
              <input
                type="number"
                className="form-control"
                value={daily}
                placeholder="Daily Limit"
                required
                onChange={(e) => setdaily(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Monthly Limit:
              <input
                type="number"
                className="form-control"
                value={monthly}
                required
                placeholder="Monthly Limit"
                onChange={(e) => setmonthly(e.target.value)}
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Cost:
              <input
                type="number"
                className="form-control"
                value={cost}
                required
                placeholder="Cost"
                onChange={(e) => setcost(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Selling Price:
              <input
                type="number"
                className="form-control"
                value={selling}
                required
                placeholder="Selling price"
                onChange={(e) => setselling(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className="fo">
          <button
            type="submit"
            className="btn btn-primary btn-md"
            disabled={!validate()}
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formp;
