import React, { useState, useEffect, useContext } from "react";
import OrderTable from "./OrderTable";

import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function Order() {
  return (
    <div className="Plans">
      <div className="main">
        <OrderTable />
      </div>
    </div>
  );
}

export default Order;
