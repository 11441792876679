import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Formp from "./editplans";
import "bootstrap/dist/css/bootstrap.min.css";

import Modal from "react-bootstrap/Modal";
import AuthContext from "../context/AuthContext";

import axios from "axios";

const PlansTable = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [PlanDetails, setPlanDetails] = useState({
    internet_plan_id: 0,
    plan_name: 0,
    download_speed: "",
    upload_speed: 0,
    daily_limit: 0,
    monthly_limit: "",
    cost: "",
    selling_price: "",
  });
  const updatePlanDetails = (details) => {
    setPlanDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/radius/plans/resellers`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);
  const columns = [
    { field: "plan_name", headerName: "Plan Name", flex: 2, minWidth: 150 },
    {
      field: "download_speed",
      headerName: "Speed",
      flex: 2,
      minWidth: 120,
    },

    {
      field: "monthly_limit",
      headerName: "Quota",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "selling_price",
      headerName: "User Price",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                internet_plan_id: params.row.internet_plan_id,
                plan_name: params.row.plan_name,
                cost: params.row.cost,
                monthly_limit: params.row.monthly_limit,
                selling_price: params.row.selling_price,
                daily_limit: params.row.daily_limit,
                upload_speed: params.row.upload_speed,
                download_speed: params.row.download_speed,
              };
              updatePlanDetails(details);
              handleShow();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];
  const getRowId = (row) => row.internet_plan_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  //Datatable HTML
  return (
    <div className="PlanTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-plan"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Edit Plan</Modal.Title>
          </Modal.Header>

          <Formp
            hi={PlanDetails.internet_plan_id}
            plan={PlanDetails.plan_name}
            cost={PlanDetails.cost}
            sell={PlanDetails.selling_price}
            down={PlanDetails.download_speed}
            up={PlanDetails.upload_speed}
            daily={PlanDetails.daily_limit}
            monthly={PlanDetails.monthly_limit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PlansTable;
