import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import AddInventory from "./InventoryAdd";
import TransferProducts from "./ProductTransfer";

import InventoryTabs from "./InventoryTabs";
import "./Inventory.css";
function Inventory() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [Tranfer, setTranfer] = useState(false);
  const handleCloseTransfer = () => setTranfer(false);

  const handleShowTransfer = () => setTranfer(true);
  return (
    <div className="Plans">
      <div className="main">
        <div>
          <button onClick={handleShow} className="AddClient">
            Add
          </button>
          <button onClick={handleShowTransfer} className="AddClient">
            Transfer
          </button>
          <NavLink
            exact
            to="/inventory/products"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
            Products
          </NavLink>

          <NavLink
            exact
            to="/inventory/services"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
            Services
          </NavLink>

          <NavLink
            exact
            to="/inventory/suppliers"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
            Suppliers
          </NavLink>
          <NavLink
            exact
            to="/inventory/warehouses"
            activeClassName="active"
            className="AddClient"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
            Warehouses
          </NavLink>
        </div>
        <InventoryTabs />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Add Items</Modal.Title>
          </Modal.Header>
          <AddInventory closeModal={handleClose} />
        </div>
      </Modal>
      <Modal show={Tranfer} onHide={handleCloseTransfer} className="modal-xl">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Transfer Products</Modal.Title>
          </Modal.Header>
          <TransferProducts closeModal={handleCloseTransfer} />
        </div>
      </Modal>
    </div>
  );
}

export default Inventory;
