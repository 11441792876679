import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function Formp(props) {
  console.log(props.hi);
  let v = props.hi;
  console.log(v);
  let sell = props.sell;

  function refreshPage() {
    window.location.reload(false);
  }
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  const [planname, setname] = useState("");
  const [download, setdownload] = useState("");
  const [upload, setupload] = useState("");
  const [daily, setdaily] = useState("");
  const [monthly, setmonthly] = useState("");
  const [cost, setcost] = useState("");
  const [selling, setselling] = useState("");
  const [expiryDateDisabled, setExpiryDateDisabled] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getpermisions = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setExpiryDateDisabled(response.data === "Reseller");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getpermisions();

    return () => {
      isMounted = false;
    };
  }, []);
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/plans/resellers`,
      {
        Action: "Put",
        reseller_id: v,
        plan_name: planname,
        download_speed: download,
        upload_speed: upload,
        daily_limit: daily,
        monthly_limit: monthly,
        cost: cost,
        selling_price: selling,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div className="form-group">
            <label>
              Selling Price:
              <input
                type="number"
                min="0"
                className="form-control"
                value={selling}
                placeholder={sell}
                onChange={(e) => setselling(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formp;
