import React, { useState, useEffect, useContext } from "react";
import MobileUsers from "./MobileUsers";

function Plans() {
  return (
    <div>
      <MobileUsers />
    </div>
  );
}

export default Plans;
