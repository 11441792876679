import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";

function Payment() {
  const [exchangeRate, setExchangeRate] = useState(0);
  const [paidUSD, setPaidUSD] = useState(0);
  const [paidLBP, setPaidLBP] = useState(0);
  const [returnedLBP, setReturnedLBP] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedItem, setSelectedItem] = useState(null); // For category
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null); // Updated variable

  const [authTokens] = useState(() =>
    JSON.parse(localStorage.getItem("authTokens"))
  );
  const { user } = useContext(AuthContext);

  const handleExchangeRateChange = (event) => {
    setExchangeRate(event.target.value);
  };

  const handlePaidUSDChange = (event) => {
    setPaidUSD(event.target.value);
  };

  const handlePaidLBPChange = (event) => {
    setPaidLBP(event.target.value);
  };

  const handleReturnedLBPChange = (event) => {
    setReturnedLBP(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const getSuppliers = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/suppliers/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setSuppliers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getSuppliers();

    return () => {
      isMounted = false;
    };
  }, []);

  const clientOptions = suppliers.map((item) => ({
    label: item.fullname_en,
    value: item.supplier_id,
  }));

  const staticOptions = [
    { label: "Expenses", value: "2" },
    { label: "Loan", value: "1" },
  ];

  const handleSubmit = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await Axios.post(
        `${API_URL}/inventory/payments/`,
        {
          username: user.username,
          paidLBP: paidLBP,
          paidUSD: paidUSD,
          exchangeRate: exchangeRate,
          returnedLBP: returnedLBP,
          supplier_id: selectedSupplier.value, // Access supplier_id properly
          description: description,
          category: selectedItem.value, // Access category value properly
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the balances after transfer
        const data = response.data;
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="collect-container">
      <h5 className="collect-heading">Payment Form</h5>
      <div className="collect-form">
        <div className="form-group row">
          <div className="col-md-6">
            <label>Supplier:</label>
            <Select
              className="search-line"
              placeholder="Choose Supplier"
              options={clientOptions}
              onChange={(opt) => setSelectedSupplier(opt)} // Save the full object
              value={selectedSupplier} // Pass the full selected object
            />
          </div>
          <div className="col-md-6">
            <label>Category:</label>
            <Select
              className="search-line"
              placeholder="Choose Category"
              options={staticOptions}
              onChange={(opt) => setSelectedItem(opt)} // Save the full object
              value={selectedItem} // Pass the full selected object
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <label>Exchange Rate:</label>
            <input
              type="number"
              value={exchangeRate}
              onChange={handleExchangeRateChange}
              className="form-control collect-input"
            />
          </div>
          <div className="col-md-6">
            <label>Paid in USD:</label>
            <input
              type="number"
              value={paidUSD}
              onChange={handlePaidUSDChange}
              className="form-control collect-input"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <label>Paid in LBP:</label>
            <input
              type="number"
              value={paidLBP}
              onChange={handlePaidLBPChange}
              className="form-control collect-input"
            />
          </div>
          <div className="col-md-6">
            <label>Returned in LBP:</label>
            <input
              type="number"
              value={returnedLBP}
              onChange={handleReturnedLBPChange}
              className="form-control collect-input"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className="form-control collect-input"
          />
        </div>

        <div className="collect-submit" onClick={handleSubmit}>
          <button className="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Payment;
