import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function TransferComponent(props) {
  const [transferAmount, setTransferAmount] = useState("");
  const [responseMessage] = useState("");
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  let v = props.sub;
  console.log(v);
  function refreshPage() {
    window.location.reload(false);
  }
  const handleTransfer = async () => {
    // Make an API request to perform the transfer
    Axios.post(
      `${API_URL}/administration/dealers/transaction/`,
      {
        amount: transferAmount,
        dealer_id: v,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <input
        type="number"
        placeholder="Enter amount"
        value={transferAmount}
        onChange={(e) => setTransferAmount(e.target.value)}
      />
      <button className="edit-client" onClick={handleTransfer}>
        Transfer
      </button>
      <p>{responseMessage}</p>
    </div>
  );
}

export default TransferComponent;
