import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import Select from "react-select";
import { useHistory } from "react-router-dom";

// For API Requests
import Axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./order.css";

function EditOrder(props) {
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const location = useLocation();
  const myProp = location.state.myProp;

  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const [isAddServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  console.log(myProp)
  const [productQuantity, setProductQuantity] = useState(1);
  const [serviceQuantity, setServiceQuantity] = useState(1);
  const [planQuantity, setPlanQuantity] = useState(1);

  const [addedProducts, setAddedProducts] = useState([]);
  const [Clients, setClients] = useState([]);

  const [client, setClient] = useState("");

  const [Products, setProducts] = useState([]);
  const [Services, setServices] = useState([]);
  const [Plans, setPlans] = useState([]);
  const [Data, setData] = useState([]);


  const [addedServices, setAddedServices] = useState([]);
  const [addedPlans, setAddedPlans] = useState([]);

  const [selectedDate, setSelectedDate] = useState(getDefaultDate());

  const [message, setMessage] = useState(null);

  const history = useHistory();

  const validate = () => {
    return (
      
      addedServices.length !== 0 ||
        addedProducts.length !== 0 ||
        addedPlans.length !== 0
    );
  };
  useEffect(() => {
    let isMounted = true;

    const getProducts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/products/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getClients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setClients(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getServices = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/services/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setServices(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/internet`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setPlans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getdetails = async () => {
        try {
          const response = await Axios.get(`${API_URL}/inventory/order/details/edit/`, {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              order_id: myProp,
            },
          });
      
          if (response.status === 200 && isMounted) {
            const data = response.data;
            console.log(response.data)
            const products = [];
            const services = [];
      
            // Loop through each item in the response data
            data.forEach((item) => {
              // Check if the item is a product or service based on the presence of product_name or service_name
              if (item.product_name && !item.service_name) {
                // If it's a product, add it to the products array
                products.push({
                  id: item.product_id, // Assuming you have a product_id in the response
                  name: item.product_name,
                  quantity: item.quantity,
                });
              } else if (item.service_name && !item.product_name) {
                // If it's a service, add it to the services array
                services.push({
                  id: item.service_id, // Assuming you have a service_id in the response
                  name: item.service_name,
                  quantity: item.quantity,
                });
              }
            });
      
            // Update the state with the parsed products and services
            setAddedProducts(products);
            setAddedServices(services);
          }
        } catch (error) {
          console.error(error);
        }
      };
    getProducts();
    getClients();
    getServices();
    getplans();
    getdetails();

    return () => {
      isMounted = false;
    };
  }, []);

  const clientOptions = Clients.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });
  const productOptions = Products.map((item) => {
    return {
      label: item.name,
      value: item.product_id,
    };
  });

  const serviceOptions = Services.map((item) => {
    return {
      label: item.name,
      value: item.service_id,
    };
  });
  const planOptions = Plans.map((item) => {
    return {
      label: item.plan_name,
      value: item.internet_plan_id,
    };
  });

  function getDefaultDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleAddProductClick = () => {
    setAddProductModalOpen(true);
  };

  const handleAddServiceClick = () => {
    setAddServiceModalOpen(true);
  };
 

  const handleAddProductModalClose = () => {
    setAddProductModalOpen(false);
    setProductQuantity("1");
  };

  const handleAddServiceModalClose = () => {
    setAddServiceModalOpen(false);
    setServiceQuantity("1");
  };
 
  const handleDeleteProduct = (index) => {
    const updatedProducts = [...addedProducts];
    updatedProducts.splice(index, 1);
    setAddedProducts(updatedProducts);
  };

  const handleDeleteService = (index) => {
    const updatedServices = [...addedServices];
    updatedServices.splice(index, 1);
    setAddedServices(updatedServices);
  };
  const handleDeletePlan = (index) => {
    const updatedPlans = [...addedPlans];
    updatedPlans.splice(index, 1);
    setAddedPlans(updatedPlans);
  };
  const handleAddProduct = () => {
    const newProduct = {
      id: selectedProduct.value,
      name: selectedProduct.label,
      quantity: productQuantity,
    };
    setAddedProducts([...addedProducts, newProduct]);
    handleAddProductModalClose();
  };

  const handleAddService = () => {
    const newService = {
      id: selectedService.value,
      name: selectedService.label,
      quantity: serviceQuantity,
    };
    setAddedServices([...addedServices, newService]);
    handleAddServiceModalClose();
  };
 

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {}, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
      history.push("/accounting/order");
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/order/details/edit/`,
      {
        Action: "Post",
        order_id:myProp,
        products: addedProducts,
        services: addedServices,
        plans: addedPlans,
        client: client,
        date: selectedDate,
        productquantity: productQuantity,
        servicequantity: serviceQuantity,
        planquantity: planQuantity,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        // refreshPage(); // You may or may not want to reload the page after successful submission
        showMessage("Order successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Order failed!", "danger"); // Show the error message
      });
  };
  return (
    <div className="order">
    
      <div className="order-body">
        <div className="table-container-products">
          {/* Products Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedProducts.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteProduct(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddProductClick}
            className="buttonWithMargin"
          >
            Add Product
          </Button>
        </div>

        <div className="table-container-services">
          {/* Services Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedServices.map((service, index) => (
                  <TableRow key={index}>
                    <TableCell>{service.name}</TableCell>
                    <TableCell>{service.quantity}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteService(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddServiceClick}
            className="buttonWithMargin"
          >
            Add Service
          </Button>
        </div>
      </div>

      {/* Add Product Modal */}
      <Dialog open={isAddProductModalOpen} onClose={handleAddProductModalClose}>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent className="modal-content">
          <div className="form-group">
            <label>
              Select Product:
              <Select
                className="search-line"
                placeholder="Choose Product"
                options={productOptions}
                onChange={(opt) => setSelectedProduct(opt)}
              />
            </label>
          </div>
          <label>
            Quantity:
            <input
              className="form-control"
              type="text"
              value={productQuantity}
              required
              onChange={(e) => setProductQuantity(e.target.value)}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddProductModalClose}>Cancel</Button>
          <Button
            onClick={handleAddProduct}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Service Modal */}
      <Dialog open={isAddServiceModalOpen} onClose={handleAddServiceModalClose}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent className="modal-content">
          <div className="form-group">
            <label>
              Select Service:
              <Select
                className="search-line"
                placeholder="Choose Service"
                options={serviceOptions}
                onChange={(opt) => setSelectedService(opt)}
              />
            </label>
          </div>
          <label>
            Quantity:
            <input
              className="form-control"
              type="text"
              value={serviceQuantity}
              required
              onChange={(e) => setServiceQuantity(e.target.value)}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddServiceModalClose}>Cancel</Button>
          <Button
            onClick={handleAddService}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        variant="contained"
        onClick={handleSubmit}
        className="submit"
        disabled={!validate()}
      >
        Submit
      </Button>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EditOrder;
