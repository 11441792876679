import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "./discounts.css"; // Import the updated CSS file

function ClientNotes(props) {
  const [notes, setNotes] = useState([]);
  const [discountDescription, setDiscountDescription] = useState("");
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);

  const getNotes = useCallback(async () => {
    try {
      const response = await Axios.get(`${API_URL}/accounting/info/note/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
        params: { username: user.username, sub_id: props.sub_id },
      });

      if (response.status === 200) {
        setNotes(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [authTokens.access, user.username, props.sub_id]);

  useEffect(() => {
    getNotes(); // Fetch notes initially

    // Auto-refresh the notes every 10 seconds
    const intervalId = setInterval(() => {
      getNotes();
    }, 10000); // 10 seconds interval

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [getNotes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios.post(
        `${API_URL}/accounting/info/note/`,
        {
          sub_id: props.sub_id,
          noteDescription: discountDescription,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setDiscountDescription("");
      getNotes(); // Fetch updated notes after submission
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {notes.length > 0 ? (
          notes.map((note) => (
            <div
              key={note.note_id}
              className={`chat-bubble ${
                note.user === user.username ? "sent" : "received"
              }`}
            >
              <div className="chat-content">
                <p className="chat-user">{note.user}</p>
                <p className="chat-text">{note.note_description}</p>
                <p className="chat-time">
                  {new Date(note.creation_date).toLocaleString()}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No Notes available.</p>
        )}
      </div>

      <form onSubmit={handleSubmit} className="chat-form">
        <div className="form-group chat-input-container">
          <textarea
            id="discount-description"
            className="form-control chat-input"
            value={discountDescription}
            placeholder="Write a note..."
            onChange={(e) => setDiscountDescription(e.target.value)}
          />
          <button type="submit" className="btn-send">
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default ClientNotes;
