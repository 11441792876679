import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import "./sub.css";
import SubInternet from "./subinternettable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DashInternetsub() {
  const location = useLocation();
  const myProp = location.state.myProp;
  const [value, setValue] = useState(myProp);
  console.log(myProp);

  useEffect(() => {
    setValue(myProp);
  }, [myProp]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="internetsub">
      <div className="main-content">
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: "white",
              },
            }}
          >
            <Tab label="Total" {...a11yProps(0)} sx={{ color: "white" }} />
            <Tab label="Online" {...a11yProps(1)} sx={{ color: "white" }} />
            <Tab label="Offline" {...a11yProps(2)} sx={{ color: "white" }} />
            <Tab label="Expired" {...a11yProps(3)} sx={{ color: "white" }} />
            <Tab label="Hold" {...a11yProps(4)} sx={{ color: "white" }} />
            <Tab label="Inactive" {...a11yProps(5)} sx={{ color: "white" }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SubInternet url={""} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SubInternet url={"online"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SubInternet url={"offline"} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SubInternet url={"expired"} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SubInternet url={"hold"} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SubInternet url={"inactive"} />
        </TabPanel>
      </div>
    </div>
  );
}
