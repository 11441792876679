import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function IssueAdd(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [description, setdescription] = useState("");

  const validate = () => {
    return description !== "";
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/issues/`,
      {
        Action: "Post",
        sub_id: props.sub,
        description: description,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <label>
            Description:
            <textarea
              className="form-control"
              style={{ width: "400px" }} // Adjust width as needed
              value={description}
              required
              onChange={(e) => setdescription(e.target.value)}
            />
          </label>

          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Add Issue
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default IssueAdd;
