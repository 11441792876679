import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../context/AuthContext";

function ConsumptionHistory(props) {
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [cat, setcat] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getorders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/consumption/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              sub_id: props.sub,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          // Convert upload and download to GB and limit decimals to 3
          const dataWithGB = response.data.map((item) => ({
            ...item,
            pppoe_upload_gb: (item.pppoe_upload / 1024 ** 3).toFixed(3),
            pppoe_download_gb: (item.pppoe_download / 1024 ** 3).toFixed(3),
          }));
          setcat(dataWithGB);
          console.log(dataWithGB);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getorders();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="testii">
      <div className="mod">
        <table className="table">
          <thead>
            <tr>
              <th>Upload (GB)</th>
              <th>Download (GB)</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.pppoe_upload_gb}</td>
                <td>{item.pppoe_download_gb}</td>
                <td>{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ConsumptionHistory;
