import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import "./History.css";

function Issues(props) {
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [cat, setcat] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getorders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/issues/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              sub_id: props.sub,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setcat(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getorders();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="testii">
      <div className="mod">
        <table className="table" style={{ color: "white" }}>
          <thead>
            <tr>
              <th>Initiator</th>
              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.fullname_en}</td>
                <td>{item.description}</td>

                <td>{item.creation_date.substring(0, 10)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Issues;
