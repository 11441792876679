import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import "./Note.css"; // Import the Note.css file
import { API_URL } from "../../components/api";

function Note(props) {
  const [clientNotes, setClientNotes] = useState(props.clientNotes);
  const [equipmentNotes, setEquipmentNotes] = useState(props.balance_notes);
  const [message, setMessage] = useState(null);
  let client_id = props.client_id;
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  console.log(props.client_id);
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });
  };

  const handleClientNotesChange = (event) => {
    setClientNotes(event.target.value);
  };

  const handleEquipmentNotesChange = (event) => {
    setEquipmentNotes(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      client_id: client_id,
      client_notes: clientNotes,
      balance_notes: equipmentNotes,
    };
    try {
      const response = await Axios.post(
        `${API_URL}/accounting/notes/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
      showMessage("Edit successful!", "success");
    } catch (error) {
      console.log(error);
      showMessage("Edit failed!", "danger");
    }
  };

  return (
    <div className="note-container">
      <h5 className="note-heading">Client Notes</h5>
      <textarea
        className="note-textarea"
        value={clientNotes}
        placeholder={props.client_notes}
        onChange={handleClientNotesChange}
        rows={4}
        cols={50}
      />

      <h5 className="note-heading">Payment Notes</h5>
      <textarea
        className="note-textarea"
        value={equipmentNotes}
        placeholder={props.balance_notes}
        onChange={handleEquipmentNotesChange}
        rows={4}
        cols={50}
      />

      <button className="note-submit-button" onClick={handleSubmit}>
        Submit
      </button>
      {message && (
        <div
          className={`alert alert-${message.type} note-message`}
          role="alert"
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Note;
