import React, { useState, useEffect, useContext, useRef } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";

function PrintInvoice(props) {
  const [cat, setCat] = useState([]);
  const printRef = useRef();
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const invoiceData = props.data;

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/invoice/details/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              invoice_id: props.invoice_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setCat(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getOrders();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, props.invoice_id, user.username]);

  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open("", "", "height=500, width=800");

    printWindow.document.write("<html><head><title>Invoice</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();

    printWindow.onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };

  const styles = {
    container: {
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      width: "800px",
      margin: "0 auto",
      border: "1px solid #ccc",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
    },
    th: {
      border: "1px solid #ccc",
      padding: "10px",
      backgroundColor: "#f2f2f2",
      textAlign: "left",
    },
    td: {
      border: "1px solid #ccc",
      padding: "10px",
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    },
  };

  return (
    <div>
      <div ref={printRef} style={styles.container}>
        <div style={styles.header}>
          <div>
            <h1>Quotation</h1>
            <p>
              <strong>Date:</strong> {invoiceData[0]?.date}
            </p>
            <p>
              <strong>Subscription Date:</strong>{" "}
              {invoiceData[0]?.subscription_date}
            </p>
          </div>
          <div>
            <p>
              <strong>Bill To:</strong>
            </p>
            <p>Name: {invoiceData[0]?.fullname_en}</p>
            <p>Location:</p>
            <p>Telephone:</p>
          </div>
        </div>

        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Item</th>
              <th style={styles.th}>Description</th>
              <th style={styles.th}>Unit</th>
              <th style={styles.th}>Rate</th>
              <th style={styles.th}>Quantity</th>
              <th style={styles.th}>Price (USD)</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td style={styles.td}>{index + 1}</td>
                <td style={styles.td}>
                  {item.product_name || item.service_name}
                </td>
                <td style={styles.td}>{item.unit || ""}</td>
                <td style={styles.td}>{item.rate || ""}</td>
                <td style={styles.td}>{item.quantity}</td>
                <td style={styles.td}>
                  ${item.product_selling || item.service_selling}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={styles.footer}>
          <div>
            <p>SUB-TOTAL:</p>
            <p>DISCOUNT:</p>
            <p>TOTAL:</p>
          </div>
          <div>
            <p>${invoiceData[0]?.total_amount}</p>
            <p>${invoiceData[0]?.discount}</p>
            <p>${invoiceData[0]?.total_amount}</p>
          </div>
        </div>
      </div>
      <button
        onClick={handlePrint}
        style={styles.button}
        className="btn btn-primary mt-3"
      >
        Print Invoice
      </button>
    </div>
  );
}

export default PrintInvoice;
