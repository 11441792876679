import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

// For API Requests
import axios from "axios";

function ReceiptTable(props) {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);
  console.log(props.daily_drawer_id);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    product_id: 0,
    code: "",
    description: "",
    barcode: "",
    name: "",
    price: 0,
    cost: 0,
    category: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/inventory/drawer/daily/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
              daily_drawer_id: props.daily_drawer_id,
              action: "Receipt",
            },
          }
        );

        if (response.status === 200) {
          setData(response.data.reverse());
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "fullname_en",
      headerName: "Client",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "paid_amount_usd",
      headerName: "Paid USD",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "paid_amount_lbp",
      headerName: "Paid LBP",
      flex: 2,
      minWidth: 100,
    },
    { field: "exchange_rate", headerName: "Rate", flex: 2, minWidth: 100 },

    {
      field: "balance_record",
      headerName: "Balance Record",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "returned_lbp",
      headerName: "Returned LBP",
      flex: 2,
      minWidth: 120,
    },
    { field: "initiator", headerName: "Cashier", flex: 2, minWidth: 100 },
    {
      field: "date",
      headerName: "Date",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        date.setHours(date.getHours() + 2);
        return date.toLocaleString();
      },
    },
  ];

  const getRowId = (row) => row.receipt_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const sumField = (field) => {
    return filteredRows.reduce((total, row) => {
      const value = parseFloat(row[field]);
      return isNaN(value) ? total : total + value;
    }, 0);
  };

  const sumPaidUsd = sumField("paid_amount_usd");
  const sumPaidLbp = sumField("paid_amount_lbp");
  const sumReturnedUsd = sumField("returned_usd");
  const sumReturnedLbp = sumField("returned_lbp");

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
          color: "white",
        }}
      >
        <div>Paid USD: {sumPaidUsd.toFixed(2)}</div>
        <div>Paid LBP: {sumPaidLbp.toFixed(2)}</div>
        <div>Returned USD: {sumReturnedUsd.toFixed(2)}</div>
        <div>Returned LBP: {sumReturnedLbp.toFixed(2)}</div>
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooter,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
    </div>
  );
}

export default ReceiptTable;
