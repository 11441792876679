import React, { useState, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Shift(props) {
  const [message, setMessage] = useState(null);
  const [confirm, setConfirm] = useState(false); // State to manage confirmation
  const [location, setLocation] = useState(""); // State to manage selected location
  const [initialBalance] = useState(0); // State to manage initial balance

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const handleConfirmation = () => {
    setConfirm(true); // Set confirmation to true
  };

  const handleCancel = () => {
    setConfirm(false); // Reset confirmation
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/shift/start/`,
      {
        userdealer: user.username,
        location: location,
        initialBalance: initialBalance,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  const handleLocationChange = (selectedOption) => {
    setLocation(selectedOption.value);
  };

  return (
    <div className="container mt-5">
      <div className="card p-4 shadow">
        {confirm ? ( // Render confirmation dialog
          <div>
            <p>Are you sure you want to create?</p>
            <Select
              options={[
                { value: "collector", label: "Collector" },
                { value: "office", label: "Office" },
              ]}
              onChange={handleLocationChange}
              placeholder="Select Location"
              className="mb-3"
            />

            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirmation}
            >
              Create
            </button>
          </div>
        )}
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Shift;
