import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";

function MCCheck(props) {
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [issues, setIssues] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/mccheck/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              client_id: props.client_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          console.log("Response data:", response.data);
          if (response.data && Array.isArray(response.data.issues)) {
            setIssues(response.data.issues);
          } else {
            console.error("Expected issues array but got:", response.data);
            setIssues([]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getOrders();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, props.client_id, user.username]);

  return (
    <div className="issues-container">
      {issues.map((item, index) => (
        <div
          key={index}
          className="issue-card"
          style={{
            border: "1px solid black",
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            color: "black",
          }}
        >
          <h5 style={{ color: "black" }}>Issue {index + 1}</h5>
          <p style={{ color: "black" }}>
            <strong>Issue:</strong> {item.issue}
          </p>
          <p style={{ color: "black" }}>
            <strong>Subscription ID:</strong> {item.subscription_id}
          </p>
        </div>
      ))}
    </div>
  );
}

export default MCCheck;
