import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import Collect from "./collect";
import Statement from "./statement";
import Receipt from "./receipt";
import Discounts from "./discounts";

import Note from "./notes";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

function Accountingtb(props) {
  const { user } = useContext(AuthContext);

  const [data, setData] = useState([]);

  const [showReceipt, setShowReceipt] = useState(false);

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [Client, setClient] = useState("");
  const [show, setShow] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [showDiscounts, setShowDiscounts] = useState(false);

  const [shift, setShift] = useState(false); // Changed to boolean type
  const [permanent, setPermanent] = useState("");
  const [temporary, setTemporary] = useState("");
  const [balance, setBalance] = useState("");

  const receiptClose = () => setShowReceipt(false);
  const receiptShow = () => setShowReceipt(true);
  const DiscountClose = () => setShowDiscounts(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const CloseCollect = () => setShow(false);
  const ShowCollect = (event) => setAnchorEl(event.currentTarget);
  const CloseNotes = () => setShowNotes(false);
  const CloseStatement = () => setShowStatement(false);

  const ShowNotes = (event) => setAnchorEl(event.currentTarget);

  const [ClientDetails, setClientDetails] = useState({
    username: "",
    password: 0,
    subId: 0,
    intSubId: 0,
    intPlanId: 0,
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    consumption: "",
    connectivity: "",
    due: "",
  });

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/accounting/info/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchShift = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/inventory/shift/status/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setShift(response.data); // Assuming response.data is boolean
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchShift();
    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            onClick={(event) => {
              ShowCollect(event);
              setClient(params.row.client_id); // Set the client when the button is clicked
              setBalance(params.row.balance_usd);
              setTemporary(params.row.client_notes);
              setPermanent(params.row.balance_notes);
            }}
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          >
            <FontAwesomeIcon icon={faHandHoldingDollar} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {shift && (
              <MenuItem
                onClick={() => {
                  setShow(true);
                  setAnchorEl(null);
                }}
              >
                Collect
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                setShowNotes(true);
                setAnchorEl(null);
              }}
            >
              Notes
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowStatement(true);
                setAnchorEl(null);
              }}
            >
              Statement
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowDiscounts(true);
                setAnchorEl(null);
              }}
            >
              Discount
            </MenuItem>
          </Menu>
        </div>
      ),
    },

    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "client_notes",
      headerName: "Client Notes",
      flex: 1,
      minWidth: 150,
    },
    { field: "discounts", headerName: "Discounts", flex: 1, minWidth: 150 },
    {
      field: "balance_notes",
      headerName: "Payment Notes",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "village_name",
      headerName: "Village Name",
      flex: 1,
      minWidth: 150,
    },
    { field: "address_string", headerName: "Address", flex: 1, minWidth: 250 },
    { field: "balance_usd", headerName: "Balance", flex: 1, minWidth: 150 },
    { field: "employee_en", headerName: "Collector", flex: 1, minWidth: 150 },
  ];

  const getRowId = (row) => row.client_id;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="InternetTable">
      <div className="actions">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-accounting"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 570, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            rowsPerPage={10}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={CloseCollect}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Collect</Modal.Title>
          </Modal.Header>
          <Collect
            client_id={Client}
            balance_usd={balance}
            handleClose={CloseCollect}
            handleOpen={receiptShow}
            fetch={fetchData}
            fetchDrawer={props.fetchDrawer}
          />
        </div>
      </Modal>
      <Modal show={showNotes} onHide={CloseNotes}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Add Notes</Modal.Title>
          </Modal.Header>
          <Note
            client_id={Client}
            balance_notes={temporary}
            client_notes={permanent}
          />
        </div>
      </Modal>
      <Modal show={showStatement} onHide={CloseStatement} className="modal-xl">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Statement Of Account</Modal.Title>
          </Modal.Header>
          <Statement client_id={Client} />
        </div>
      </Modal>
      <Modal show={showReceipt} onHide={receiptClose}>
        <div className="receipt-modal">
          <Modal.Header closeButton>
            <Modal.Title>Receipt</Modal.Title>
          </Modal.Header>
          <Receipt client_id={Client} close={receiptClose} />
        </div>
      </Modal>
      <Modal show={showDiscounts} onHide={DiscountClose} className="modal-lg">
        <div className="receipt-modal">
          <Modal.Header closeButton>
            <Modal.Title>Add Discount</Modal.Title>
          </Modal.Header>
          <Discounts client_id={Client} close={DiscountClose} />
        </div>
      </Modal>
    </div>
  );
}

export default Accountingtb;
