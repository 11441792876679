import React, { useState, useEffect, useContext } from "react";

import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../context/AuthContext";
import "./SubDealers.css";
//For API Requests
import axios from "axios";

function TransactionsTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    dealer_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/dealers/transaction/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "dealer_transaction_details",
      headerName: "Details",
      flex: 1,
      minWidth: 850,
    },
    {
      field: "amount",
      headerName: "Amount",

      minWidth: 50,
    },
    {
      field: "action",
      headerName: "Action",

      minWidth: 50,
    },
    {
      field: "credit_account",
      headerName: "Credit",

      minWidth: 50,
    },
    {
      field: "debit_account",
      headerName: "Debit",

      minWidth: 50,
    },
    {
      field: "creation_date",
      headerName: "Date",

      minWidth: 230,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        // Extract time
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        // Format the date as dd-mm-yyyy hh:mm:ss
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      },
    },
  ];

  const getRowId = (row) => row.dealer_transaction_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
    </div>
  );
}

export default TransactionsTable;
