import React, { useState, useEffect, useContext } from "react";
import ProductCategoriesTable from "./ProductCategoriesTable";
import Modal from "react-bootstrap/Modal";
import AddProductCategory from "./ProductCategoriesAdd";

import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function ProductCategories() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);

  const handleShowAdd = () => setShowAdd(true);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.username]);
  return (
    <div className="Plans">
      <div className="main">
        <div>
          <button onClick={handleShowAdd} className="AddClient">
            Add Category
          </button>

          <NavLink
            exact
            to="/inventory"
            activeClassName="active"
            className="AddClient"
          >
            Go Back
          </NavLink>
        </div>
        <ProductCategoriesTable />
      </div>
      <Modal show={showAdd} onHide={handleCloseAdd}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <AddProductCategory />
        </div>
      </Modal>
    </div>
  );
}

export default ProductCategories;
