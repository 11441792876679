import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "./discounts.css"; // Import the CSS file

function Discount(props) {
  const [users, setUsers] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");
  const [username, setUsername] = useState("");

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const getClient = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discount/add/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, client_id: props.client_id },
        }
      );

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [authTokens.access, user.username, props.client_id]);

  const getDiscounts = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discounts/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, client_id: props.client_id },
        }
      );

      if (response.status === 200) {
        setDiscounts(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [authTokens.access, user.username, props.client_id]);

  useEffect(() => {
    getClient();
    getDiscounts();
  }, [getClient, getDiscounts]);

  const userOptions = users.map((item) => ({
    label: item.username,
    value: item.subscription_id,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.post(
        `${API_URL}/radius/subscriptions/discount/add/`,
        {
          sub_id: username,
          discountPercentage,
          discountAmount,
          discountDescription,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
      refreshPage();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="discount-form-container">
      <form onSubmit={handleSubmit} className="discount-form">
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <Select
            id="username"
            className="select-input"
            placeholder="Choose Username"
            options={userOptions}
            onChange={(opt) => setUsername(opt.value)}
            value={userOptions.find((option) => option.value === username)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-amount">Discount Amount:</label>
          <input
            id="discount-amount"
            className="form-control"
            type="number"
            value={discountAmount}
            placeholder="Discount Amount"
            onChange={(e) => setDiscountAmount(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-percentage">Discount Percentage:</label>
          <input
            id="discount-percentage"
            className="form-control"
            type="number"
            value={discountPercentage}
            placeholder="Discount Percentage"
            onChange={(e) => setDiscountPercentage(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-description">Description:</label>
          <textarea
            id="discount-description"
            className="form-control"
            value={discountDescription}
            placeholder="Description"
            onChange={(e) => setDiscountDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-primary">
            Submit
          </button>
        </div>
      </form>

      <div className="discount-list">
        <h3>Current Discounts</h3>
        {discounts.length > 0 ? (
          <table className="discount-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Percentage</th>
                <th>Description</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {discounts.map((discount) => (
                <tr key={discount.discount_id}>
                  <td>{discount.discount_id}</td>
                  <td>{discount.amount}</td>
                  <td>{discount.percentage}</td>
                  <td>{discount.description}</td>
                  <td>{new Date(discount.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No discounts available.</p>
        )}
      </div>
    </div>
  );
}

export default Discount;
