import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../context/AuthContext";

function Ping(props) {
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  const [data, setData] = useState("");

  useEffect(() => {
    let isMounted = true;

    const ping = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/ping/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            ip: props.ip,
          },
        });

        if (response.status === 200 && isMounted) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    ping();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="testi">
      <h4>Status: {data.status}</h4>
      <h4>Average: {data.average}</h4>

      {/* Displaying details array */}
      <h4>Details:</h4>
      <ul>
        {data.details && data.details.length > 0 ? (
          data.details.map((detail, index) => <li key={index}>{detail +" ms"}</li>)
        ) : (
          <p>No details available</p>
        )}
      </ul>
    </div>
  );
}

export default Ping;
