import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function ResetPassword(props) {
  const user_id = props.user_id;
  console.log(user_id);
  function refreshPage() {
    window.location.reload(false);
  }
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  const [password, setPassword] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/mobile/users/`,
      {
        user_id: user_id,
        userdealer: user.username,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            New Password:
            <input
              type="text"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>

        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
