import React, { useState, useEffect, useContext } from "react";
import PlansTable from "./planstb";
import Modal from "react-bootstrap/Modal";
import Formp from "./createplans";
import Formpr from "./createresellerplans";
import "./plans.css";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { API_URL } from "../components/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function PlansRes() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);

  const handleShowAdd = () => setShowAdd(true);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.username]);
  return (
    <div className="Plans">
      <div className="main">
        <div>
          <button onClick={handleShowAdd} className="AddClient">
            Add Plan
          </button>
          <button className="AddClient">
            <NavLink
              exact
              to="/resellers"
              activeClassName="active"
              className="nav-inner"
            >
              Go Back
            </NavLink>
          </button>
        </div>
        <PlansTable />
      </div>
      <Modal show={showAdd} onHide={handleCloseAdd}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Add Plan</Modal.Title>
          </Modal.Header>
          <Formpr />
        </div>
      </Modal>
    </div>
  );
}

export default PlansRes;
