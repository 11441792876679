import React, { useState, useContext } from "react";
import Axios from "axios";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import "./MobileNotification.css";

function Notification(props) {
  const [EngTitle, setEngTitle] = useState("");
  const [ArTitle, setArTitle] = useState("");
  const [EngMessage, setEngMessage] = useState("");
  const [ArMessage, setArMessage] = useState("");
  const [message, setMessage] = useState(null);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });
    if (type === "success") {
      setTimeout(() => {
        setMessage(null);
        props.deselectAllUsers();
        props.closeModal();
      }, 4000);
    }
  };

  const handleEngTitleChange = (event) => {
    setEngTitle(event.target.value);
  };

  const handleEngMessageChange = (event) => {
    setEngMessage(event.target.value);
  };

  const handleArTitleChange = (event) => {
    setArTitle(event.target.value);
  };

  const handleArMessageChange = (event) => {
    setArMessage(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!EngTitle || !ArTitle || !EngMessage || !ArMessage) {
      showMessage("All fields are required.", "danger");
      return;
    }

    const requestData = {
      EngMessage,
      EngTitle,
      ArMessage,
      ArTitle,
      userdealer: user.username,
      user_ids: props.user_ids,
    };
    try {
      const response = await Axios.post(
        `${API_URL}/radius/subscriptions/mobile/notification/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
      showMessage("Notification sent successfully!", "success");
    } catch (error) {
      console.log(error);
      showMessage("Notification sending failed!", "danger");
    }
  };

  return (
    <div className="note-container">
      <div className="note-row">
        <h5 className="note-heading">English Title</h5>
        <textarea
          className="note-textarea"
          placeholder="Enter message title"
          onChange={handleEngTitleChange}
          value={EngTitle}
          rows={2}
          required
        />
      </div>
      <div className="note-row">
        <h5 className="note-heading">English Text</h5>
        <textarea
          className="note-textarea"
          placeholder="Enter message text"
          onChange={handleEngMessageChange}
          value={EngMessage}
          rows={4}
          required
        />
      </div>
      <div className="note-row">
        <h5 className="note-heading">Title Arabic</h5>
        <textarea
          className="note-textarea"
          placeholder="Enter message title in Arabic"
          onChange={handleArTitleChange}
          value={ArTitle}
          rows={2}
          required
        />
      </div>
      <div className="note-row">
        <h5 className="note-heading">Text Arabic</h5>
        <textarea
          className="note-textarea"
          placeholder="Enter message text in Arabic"
          onChange={handleArMessageChange}
          value={ArMessage}
          rows={4}
          required
        />
      </div>
      <button className="note-submit-button" onClick={handleSubmit}>
        Submit
      </button>
      {message && (
        <div
          className={`alert alert-${message.type} note-message`}
          role="alert"
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Notification;
