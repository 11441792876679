import React from "react";
import "./Receipt.css";

function Print(props) {
  const { clientName, balanceUSD, balanceLBP, paid, remaining, discount } =
    props;

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Receipt</title></head><body>"
    );
    printWindow.document.write("<div class='receipt'>");

    // Render the receipt content
    printWindow.document.write(`<h1>Receipt</h1>`);
    printWindow.document.write(
      `<p>Date: ${new Date().toLocaleDateString()}</p>`
    );
    printWindow.document.write(`<table style="width: 100%;">`);
    printWindow.document.write(`<tbody>`);
    printWindow.document.write(
      `<tr><td>Client Name:</td><td>${clientName}</td></tr>`
    );
    printWindow.document.write(
      `<tr><td>Balance (USD):</td><td>${balanceUSD}</td></tr>`
    );
    printWindow.document.write(
      `<tr><td>Balance (LBP):</td><td>${balanceLBP}</td></tr>`
    );
    printWindow.document.write(`<tr><td>Paid:</td><td>${paid}</td></tr>`);
    printWindow.document.write(
      `<tr><td>Remaining:</td><td>${remaining}</td></tr>`
    );
    printWindow.document.write(
      `<tr><td>Discount:</td><td>${discount}</td></tr>`
    );
    printWindow.document.write(`</tbody>`);
    printWindow.document.write(`</table>`);

    printWindow.document.write("</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="receipt">
      <h1>Receipt</h1>
      <p>Date: {new Date().toLocaleDateString()}</p>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Client Name:</td>
            <td>{clientName}</td>
          </tr>
          <tr>
            <td>Balance (USD):</td>
            <td>{balanceUSD}</td>
          </tr>
          <tr>
            <td>Balance (LBP):</td>
            <td>{balanceLBP}</td>
          </tr>
          <tr>
            <td>Paid:</td>
            <td>{paid}</td>
          </tr>
          <tr>
            <td>Remaining:</td>
            <td>{remaining}</td>
          </tr>
          <tr>
            <td>Discount:</td>
            <td>{discount}</td>
          </tr>
        </tbody>
      </table>
      <button className="print-button" onClick={handlePrint}>
        Print Receipt
      </button>
    </div>
  );
}

export default Print;
