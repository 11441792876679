import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Alert } from "react-bootstrap";
import "./AdminConsole.css"; // Import the CSS file
import { API_URL } from "../components/api";
import TransactionsTable from "./transactionstb";
import AuthContext from "../context/AuthContext";
import "./SubDealers.css";

function AdminConsole() {
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/administration/dealers/info/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data[0]);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/administration/dealers/transaction/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setTransactions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTransactions();
    fetchData();
  }, [user.username]);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };

  const handleCashout = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/administration/dealers/cashout/`,
        {
          username: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        showMessage("Cashout successful!", "success");
        fetchData(); // Refetch data to show updated information
      }
    } catch (error) {
      console.error(error);
      showMessage("Cashout failed!", "danger");
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="admin-console">
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setMessage(null)}
          ></button>
        </div>
      )}
      <div className="right-console">
        <div className="right-admin-console">
          <h4>Company:</h4>
          <h4 style={{ marginLeft: "10px" }}>{data.company_name}</h4>
        </div>
        <div className="right-admin-console">
          <h4>Balance:</h4>
          <h4 style={{ marginLeft: "10px" }}>{" $ " + data.credits}</h4>
        </div>
        <div className="right-admin-console">
          <h4>Commission:</h4>
          <h4 style={{ marginLeft: "10px" }}>{" $ " + data.commission}</h4>
        </div>
        <div className="right-admin-console">
          <button className="AddClient" onClick={() => setShowModal(true)}>
            Cashout
          </button>
        </div>
      </div>
      <div className="left-admin-console">
        <TransactionsTable />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cashout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cash out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCashout}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminConsole;
