import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function EditInventory(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [site, setsite] = useState("");
  let [plans, setplans] = useState([]);
  const [client, setclient] = useState("");
  const [plani, setplano] = useState("");
  let [notes, setcil] = useState([]);
  console.log(plani);
  console.log(client);

  useEffect(() => {
    let isMounted = true;

    const getproducts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/products/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setcil(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getwarehouses = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/warehouses/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getproducts();
    getwarehouses();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = notes.map((item) => {
    return {
      label: item.name,
      value: item.product_id,
    };
  });

  const plano = plans.map((item) => {
    return {
      label: item.warehouse_name,
      value: item.warehouse_id,
    };
  });

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/`,
      {
        Action: "Put",
        inventory: props.inventory,
        product: client,
        warehouse: plani,
        quantity: site,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="name">
            <div className="form-group">
              <label>
                Products:
                <Select
                  className="search-line"
                  placeholder={props.product}
                  options={options}
                  onChange={(opt) => setclient(opt.value)}
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                Warehouses:
                <Select
                  className="search-line"
                  placeholder={props.warehouse}
                  options={plano}
                  onChange={(opt) => setplano(opt.value)}
                />
              </label>
            </div>
          </div>

          <label>
            Quantity:
            <input
              className="form-control"
              type="number"
              step="1"
              min="0"
              value={site}
              placeholder={props.quantity}
              onChange={(e) => setsite(e.target.value)}
            />
          </label>
          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EditInventory;
