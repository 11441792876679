import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import EditWarehouse from "./WarehouseEdit";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

//For API Requests
import axios from "axios";

function WarehouseTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    warehouse_id: 0,
    manager: "",
    name: "",
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/warehouses/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "warehouse_name", headerName: "Name", flex: 2, minWidth: 50 },
    {
      field: "manager",
      headerName: "Manager",
      flex: 2,
      minWidth: 50,
    },

    {
      field: "actions",
      headerName: "Actions",
      minwidth: 100,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                warehouse_id: params.row.warehouse_id,
                name: params.row.warehouse_name,
                manager: params.row.employee_id,
              };
              updateDealerDetails(details);
              handleshowo();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.warehouse_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Warehouse</Modal.Title>
        </Modal.Header>
        <EditWarehouse
          manager={DealerDetails.manager}
          name={DealerDetails.name}
          warehouse_id={DealerDetails.warehouse_id}
        />
      </Modal>
    </div>
  );
}

export default WarehouseTable;
