import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import EditService from "./subscriptionServiceEdit";

import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../context/AuthContext";

//For API Requests
import axios from "axios";

function ServiceTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const showoDash = () => setDasho(true);
  const handledash = () => setDasho(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    service_id: 0,
    code: "",
    description: "",
    name: "",
    price: 0,
    cost: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/radius/subscriptions/services/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "status", headerName: "Status", flex: 2, minWidth: 50 },
    { field: "username", headerName: "Username", flex: 2, minWidth: 50 },
    { field: "fullname_en", headerName: "Client", flex: 2, minWidth: 50 },
    { field: "name", headerName: "Service", flex: 2, minWidth: 50 },
    { field: "description", headerName: "Description", flex: 2, minWidth: 50 },
    {
      field: "selling_price",
      headerName: "Price",
      flex: 1,
      minWidth: 60,
      valueFormatter: (params) => {
        // Format the value by adding a dollar sign
        return `$ ${params.value}`;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minwidth: 100,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                service_id: params.row.service_id,
                description: params.row.description,
                code: params.row.code,
                name: params.row.name,
                price: params.row.selling_price,
                cost: params.row.cost,
              };
              updateDealerDetails(details);
              handleshowo();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_service_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <EditService
          service_id={DealerDetails.service_id}
          name={DealerDetails.name}
          code={DealerDetails.code}
          description={DealerDetails.description}
          cost={DealerDetails.cost}
          price={DealerDetails.price}
        />
      </Modal>
    </div>
  );
}

export default ServiceTable;
