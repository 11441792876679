import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "./Clients.css";
import Formo from "./Editform";

import Modal from "react-bootstrap/Modal";

import AuthContext from "../context/AuthContext";

//For API Requests
import axios from "axios";

function ClientTable(props) {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  console.log(props.selectedOption);
  const [ClientDetails, setClientDetails] = useState({
    client_id: 0,
    info_id: 0,
    address_id: "",
    first_name: 0,
    middle_name: 0,
    last_name: "",
    phone_number: "",
    address_string: "",
    village_name: "",
  });

  const updateClientDetails = (details) => {
    setClientDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/reseller/clients/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              dealer: props.selectedOption,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "fullname_en", headerName: "Full Name", flex: 2, minWidth: 250 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "village_name",
      headerName: "Village Name",
      flex: 1,
      minWidth: 150,
    },
    { field: "address_string", headerName: "Address", flex: 1, minWidth: 250 },

    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                client_id: params.row.client_id,
                address_id: params.row.address_id,
                info_id: params.row.info_id,
                first_name: params.row.first_name,
                middle_name: params.row.middle_name,
                last_name: params.row.last_name,
                phone_number: params.row.phone_number,
                address_string: params.row.address_string,
                village_name: params.row.village_name,
              };
              updateClientDetails(details);
              handleShow();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.client_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Client</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <Formo
            sub={ClientDetails.client_id}
            add={ClientDetails.address_id}
            inf={ClientDetails.info_id}
            fir={ClientDetails.first_name}
            mid={ClientDetails.middle_name}
            las={ClientDetails.last_name}
            addr={ClientDetails.address_string}
            pho={ClientDetails.phone_number}
            vill={ClientDetails.village_name}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ClientTable;
