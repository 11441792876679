import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import SubInternet from "./SubscriptionTab";
import ClientTable from "./ClientsTab";
import TransactionsTable from "./TransactionTab";
import DashboardTab from "./DashboardTab";
import "./subdealerdetails.css";

function SubResellersDetails(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedView, setSelectedView] = useState("dashboard");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/administration/subreseller/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
              dealer: props.selectedOption,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
          if (response.data.length > 0) {
            setSelectedOption(response.data[0].dealer_id);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  useEffect(() => {
    console.log("Selected Option value changed:", selectedOption);
  }, [selectedOption]);

  const options = data.map((item) => ({
    label: item.fullname_en,
    value: item.dealer_id,
  }));

  const viewOptions = [
    { label: "Dashboard", value: "dashboard" },
    { label: "Subscriptions", value: "subscriptions" },
    { label: "Clients", value: "clients" },
    { label: "Transactions", value: "transactions" },
  ];

  const handleSelectChange = (selected) => {
    setSelectedOption(selected?.value); // Store only the value
  };

  const handleViewChange = (selected) => {
    setSelectedView(selected.value);
  };

  const renderViewContent = () => {
    switch (selectedView) {
      case "dashboard":
        return (
          <DashboardTab key={selectedOption} selectedOption={selectedOption} />
        );
      case "subscriptions":
        return (
          <SubInternet key={selectedOption} selectedOption={selectedOption} />
        );
      case "clients":
        return (
          <ClientTable key={selectedOption} selectedOption={selectedOption} />
        );
      case "transactions":
        return (
          <TransactionsTable
            key={selectedOption}
            selectedOption={selectedOption}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="Clients">
      <div className="main">
        {!loading && (
          <div
            className="selectors-container"
            style={{
              display: "flex",
              gap: "10px",
              width: "50%",
              margin: "10px",
              justifyContent: "space-around",
              
            }}
          >
            <h4 style={{ color: "white" }}>Sub Reseller: </h4>
            <Select
              value={options.find((option) => option.value === selectedOption)}
              onChange={handleSelectChange}
              options={options}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
            <h4 style={{ color: "white" }}>Option: </h4>
            <Select
              value={viewOptions.find(
                (option) => option.value === selectedView
              )}
              onChange={handleViewChange}
              options={viewOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        )}

        <div className="view-content mt-3">{renderViewContent()}</div>
      </div>
    </div>
  );
}

export default SubResellersDetails;
