import React, { useState, useEffect, useContext } from "react";
import EditInventory from "./InventoryEdit";
import Modal from "react-bootstrap/Modal";

import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AuthContext from "../context/AuthContext";
import "./InventoryTable.css";
//For API Requests
import axios from "axios";

function InventoryTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const showoDash = () => setDasho(true);
  const handledash = () => setDasho(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    inventory_id: 0,
    product: "",
    product_id: "",
    quantity: "",
    warehouse_id: "",
    warehouse: "",
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "product_name", headerName: "Product Name", flex: 2, minWidth: 150 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "total_quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 80,
    },
  ];

  const getRowId = (row) => row.product_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <EditInventory
          closeModal={handleedit}
          inventory={DealerDetails.inventory_id}
          warehouse={DealerDetails.warehouse}
          product={DealerDetails.product}
          quantity={DealerDetails.quantity}
        />
      </Modal>
    </div>
  );
}

export default InventoryTable;
