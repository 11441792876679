import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import axios from "axios";

const TransferProducts = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [products, setProducts] = useState([]);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/warehouses/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setWarehouses(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchProducts = async (warehouseId) => {
    if (!warehouseId) {
      setProducts([]);
      return;
    }

    setLoadingProducts(true);

    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/inventory/warehouses/products/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
            warehouse_id: warehouseId,
          },
        }
      );

      if (response.status === 200) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleFromWarehouseChange = (warehouseId) => {
    setFromWarehouse(warehouseId);
    setToWarehouse("");
    setSelectedProductId("");
    setQuantity(1);
    fetchProducts(warehouseId);
  };

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
    setQuantity(1); // Reset quantity when product changes
  };

  const handleTransfer = () => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens"));

    axios
      .post(
        `${API_URL}/inventory/transfer/`,
        {
          from_warehouse_id: fromWarehouse,
          to_warehouse_id: toWarehouse,
          products: [{ product_id: selectedProductId, quantity }],
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      )
      .then((response) => {
        setAlertMessage("Transfer successful!");
        setAlertType("success");
      })
      .catch((error) => {
        setAlertMessage("Transfer failed. Please try again.");
        setAlertType("danger");
        console.error(error);
      });
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Transfer Products</h1>

      {alertMessage && (
        <div className={`alert alert-${alertType}`} role="alert">
          {alertMessage}
        </div>
      )}

      <div className="row">
        <div className="col-md-4">
          <label className="form-label">
            From Warehouse:
            <select
              className="form-select"
              value={fromWarehouse}
              onChange={(e) => handleFromWarehouseChange(e.target.value)}
            >
              <option value="">Select Warehouse</option>
              {warehouses.map((warehouse) => (
                <option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="col-md-4">
          <label className="form-label">
            To Warehouse:
            <select
              className="form-select"
              value={toWarehouse}
              onChange={(e) => setToWarehouse(e.target.value)}
            >
              <option value="">Select Warehouse</option>
              {warehouses.map((warehouse) => (
                <option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <label className="form-label">
            Products from {fromWarehouse && `Warehouse ${fromWarehouse}`}:
            <select
              className="form-select"
              value={selectedProductId}
              onChange={(e) => handleProductChange(e.target.value)}
            >
              <option value="">Select Product</option>
              {loadingProducts ? (
                <option>Loading products...</option>
              ) : (
                products.map((product) => (
                  <option key={product.product_id} value={product.product_id}>
                    {product.name}
                  </option>
                ))
              )}
            </select>
          </label>
        </div>
      </div>

      {selectedProductId && (
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="form-label">
              Quantity for selected product:
              <input
                type="number"
                className="form-control"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
              />
            </label>
          </div>
        </div>
      )}

      <div className="row mt-3">
        <div className="col-md-6">
          <button
            className="btn btn-primary"
            onClick={handleTransfer}
            disabled={
              !fromWarehouse ||
              !toWarehouse ||
              !selectedProductId ||
              quantity < 1
            }
          >
            Transfer Products
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferProducts;
