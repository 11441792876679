import React, { useState, useEffect, useContext } from "react";
import "./forma.css";
import Axios from "axios";
import { API_URL } from "../components/api";

import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Form(props) {
  let [notes, setvillage] = useState([]);
  let [employee, setemployee] = useState([]);
  let [remote, setRemote] = useState(0); // New state for remote, default value 0
  console.log(remote);
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    let isMounted = true;

    const getvillage = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/villages/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: "admin",
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setvillage(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getemployee = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/employees/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: "admin",
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setemployee(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getvillage();
    getemployee();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = notes.map((item) => {
    return {
      label: item.village_name,
      value: item.village_id,
    };
  });

  const employees = employee.map((item) => {
    return {
      label: item.first_name,
      value: item.employee_id,
    };
  });

  const [firstname, setfirst] = useState("");
  const [middlename, setmiddles] = useState("");
  const [lastname, setlast] = useState("");
  const [address, setaddress] = useState("");
  const [phone, setphone] = useState("");
  const [village, setvill] = useState("");
  const [category, setcategory] = useState("");

  const [collector, setcollector] = useState("");

  console.log(village);

  const validate = () => {
    return (
      firstname !== "" &&
      lastname !== "" &&
      phone !== "" &&
      middlename !== "" &&
      village !== "" &&
      category !== "" &&
      address !== ""
    );
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/administration/clients/`,
      {
        Action: "Post",
        first_name: firstname,
        email_address: "-",
        address_string: address,
        phone_number: phone,
        middle_name: middlename,
        last_name: lastname,
        userdealer: user.username,
        collector_id: collector,
        category: category,
        village_id: village,
        remote: remote, // Include remote in the POST request payload
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dua = [
    { value: "VIP", label: "VIP" },
    { value: "Standard", label: "Standard" },
  ];
  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="name">
            <div className="form-group">
              <label>
                First Name:
                <input
                  className="form-control"
                  type="text"
                  value={firstname}
                  required
                  placeholder="First Name"
                  onChange={(e) => {
                    setfirst(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Middle Name:
                <input
                  className="form-control"
                  type="text"
                  value={middlename}
                  required
                  placeholder="Middle Name"
                  onChange={(e) => setmiddles(e.target.value)}
                />
              </label>
            </div>

            <div className="form-group">
              <label>
                Last Name:
                <input
                  className="form-control"
                  type="text"
                  value={lastname}
                  required
                  placeholder="Last Name"
                  onChange={(e) => setlast(e.target.value)}
                />
              </label>
            </div>
          </div>

          <label>
            Phone:
            <input
              className="form-control"
              type="number"
              value={phone}
              required
              placeholder="phone"
              onChange={(e) => setphone(e.target.value)}
            />
          </label>

          <label>
            Village:
            <Select
              className="search-line"
              placeholder="Choose Village"
              options={options}
              onChange={(opt) => setvill(opt.value)}
            />
          </label>

          <label>
            Address:
            <input
              className="form-control"
              type="text"
              value={address}
              required
              placeholder="Address"
              onChange={(e) => setaddress(e.target.value)}
            />
          </label>
          <div className="form-group">
            <label>
              Collector:
              <Select
                className="search-line"
                required
                options={employees}
                onChange={(opt) => setcollector(opt.value)}
              />
            </label>
            <label>
              Category:
              <Select
                className="search-line"
                options={dua}
                onChange={(opt) => setcategory(opt.value)}
              />
            </label>
            {/* Checkbox for remote */}

            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label style={{ marginRight: "10px" }}>Temp Collection:</label>
              <input
                type="checkbox"
                checked={remote === 1}
                onChange={(e) => setRemote(e.target.checked ? 1 : 0)}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>

          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              onClick={() => {
                props.parentFunction();
              }}
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
