import React, { useState, useEffect, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import AuthContext from "../context/AuthContext";
import axios from "axios";
import DealerTable from "./Subdealertb";
import { API_URL } from "../components/api";
import "./reseller.css";
function Reseller() {
  return (
    <div className="Plans">
      <div className="main">
        <div>
          <button className="AddClient">
            <NavLink
              exact
              to="/plans/reseller"
              activeClassName="active"
              className="nav-inner"
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Sub Reseller Plans
            </NavLink>
          </button>
        </div>
        <DealerTable />
      </div>
    </div>
  );
}

export default Reseller;
