import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../components/api";
import PrintInvoice from "./PrintInvoice";
import EditInvoice from "./EditInvoice";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../context/AuthContext";

// For API Requests
import axios from "axios";

function InvoiceTable() {
  const [data, setData] = useState([]);
  const [showdetails, setShowDetails] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [showprint, setShowPrint] = useState(false);

  const [showStatusPrompt, setShowStatusPrompt] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [invoice, setInvoice] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const handleshowDetails = () => setShowDetails(true);
  const handlecloseDetails = () => setShowDetails(false);
  const handleshowPrint = () => setShowPrint(true);
  const handleclosePrint = () => setShowPrint(false);
  const handleshowEdit = () => setShowEdit(true);
  const handlecloseEdit = () => setShowEdit(false);
  const [dealerDetails, setDealerDetails] = useState({
    order_id: 0,
    invoice_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/accounting/dealer/accounting/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
              type: "invoices",
            },
          }
        );

        if (response.status === 200) {
          setData(response.data.reverse());
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username, authTokens.access]);

  const columns = [
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 150 },
    { field: "date", headerName: "Date", flex: 2, minWidth: 50 },
    { field: "total_amount", headerName: "Total", flex: 2, minWidth: 50 },

    /*{
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                invoice_id: params.row.invoice_id,
              };
              updateDealerDetails(details);
              handleshowEdit();
            }}
          >
            Edit
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                order_id: params.row.order_id,
                invoice_id: params.row.invoice_id,
              };
              updateDealerDetails(details);
              handleshowPrint();
            }}
          >
            Print
          </button>
        </div>
      ),
    },*/
  ];

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };

  const getRowId = (row) => row.invoice_id;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusCellClick = (params) => {
    if (params.row.status === "Pending") {
      setNewStatus("Done");
      setSelectedRow(params.row);
      setInvoice(params.row.invoice_id); // Set the invoice ID when the status cell is clicked
      setShowStatusPrompt(true);
    } else {
      setNewStatus(params.row.status || "Done"); // Set default value if status is not present
      setShowStatusPrompt(true);
      setSelectedRow(params.row);
      setInvoice(params.row.invoice_id); // Set the invoice ID when the status cell is clicked
    }
  };

  const handleStatusPromptClose = () => {
    setNewStatus("");
    setShowStatusPrompt(false);
    setSelectedRow(null);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/invoices/status/`,
        {
          status: newStatus,
          invoice: invoice,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      console.log(response);
      showMessage("Status changed!", "success");
      // Fetch updated data
      const updatedData = await axios.get(`${API_URL}/inventory/invoices/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });
      setData(updatedData.data.reverse());
    } catch (error) {
      console.log(error);
      showMessage("Edit failed!", "danger");
    }
  };

  const handleStatusConfirmation = () => {
    console.log("Invoice ID:", selectedRow.invoice_id);
    console.log("New Status:", newStatus);
    // Perform any additional actions here (e.g., API call, state update)
    handleSubmit();
    handleStatusPromptClose();
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showedit} onHide={handlecloseEdit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <EditInvoice
          invoice_id={dealerDetails.invoice_id}
          order_id={dealerDetails.order_id}
        />
      </Modal>
      <Modal show={showprint} onHide={handleclosePrint} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Print</Modal.Title>
        </Modal.Header>
        <PrintInvoice
          invoice_id={dealerDetails.invoice_id}
          order_id={dealerDetails.order_id}
          data={data.filter(
            (item) => item.invoice_id === dealerDetails.invoice_id
          )}
          data1={data}
        />
      </Modal>
      <Modal show={showStatusPrompt} onHide={handleStatusPromptClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="newStatus">Select new status:</label>
          <select
            id="newStatus"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="form-select"
          >
            <option value="Done">Done</option>
            <option value="Blocked">Blocked</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleStatusPromptClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleStatusConfirmation}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default InvoiceTable;
