import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "../components/api";
import "./dashboard.css";
import Chart from "./graph";
import AuthContext from "../context/AuthContext";

import axios from "axios";

function Dashboard(props) {
  const { user } = useContext(AuthContext);
  const v = props.sub;
  console.log(v);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [data, setData] = useState({
    online: 0,
    offline: 0,
    expired: 0,
    inactive: 0,
    hold: 0,
  });

  const fetchData = (option) => {
    axios
      .get(`${API_URL}/radius/dashboard/reseller`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: v,
          option: option,
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          [option]: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData("online");
    fetchData("offline");
    fetchData("expired");
    fetchData("inactive");
    fetchData("hold");
  }, [authTokens.access, user.username]);

  return (
    <div className="Dashboard">
      <div className="top-cards">
        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.online}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-1"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>Online</h4>
          </div>
        </div>

        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.offline}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-2"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>Offline</h4>
          </div>
        </div>
        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.expired}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-3"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>Expired</h4>
          </div>
        </div>
      </div>
      <div className="top-cards">
        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.hold}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-4"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>On-Hold</h4>
          </div>
        </div>
        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.inactive}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-5"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>Inactive</h4>
          </div>
        </div>
        <div className="card1">
          <div className="card-top">
            <div className="card-left">
              <h4>{data.offline + data.online}</h4>
            </div>
            <div className="card-right">
              <div className="card-right-inner-5"></div>
            </div>
          </div>
          <div className="card-bottom">
            <hr className="divider" />
            <h4>Total</h4>
          </div>
        </div>
      </div>
      <div className="chart">
        <Chart sub={v} />
      </div>
    </div>
  );
}

export default Dashboard;
