import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { API_URL } from "../components/api";
import "./dashboard.css";
import Chart from "./graph";
import ChartOSS from "./graphOSS";

import AuthContext from "../context/AuthContext";

import axios from "axios";

function Dashboard() {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [data, setData] = useState({
    online: 0,
    offline: 0,
    expired: 0,
    inactive: 0,
    hold: 0,
  });

  const fetchData = (option) => {
    axios
      .get(`${API_URL}/radius/dashboard/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
          option: option,
        },
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          [option]: res.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPermissions = async () => {
    try {
      const response = await axios.get(`${API_URL}/administration/groups/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setGroup(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false once permissions are fetched
    }
  };
  const Subscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", {
      myProp: 0,
    });
  };
  const inactiveSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 5 });
  };
  const offlineSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 2 });
  };
  const onlineSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 1 });
  };
  const expiredSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 3 });
  };
  const holdSubscriptions = () => {
    history.push("/subscriptions/internet/tabs/osss", { myProp: 4 });
  };
  useEffect(() => {
    fetchData("online");
    fetchData("offline");
    fetchData("expired");
    fetchData("inactive");
    fetchData("hold");
    getPermissions();
  }, [authTokens.access, user.username]);

  const isGroupAllowed = group === "OSS" || group === "yes";

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Dashboard">
      <div className="top-cards">
        {group !== "OSS" && (
          <>
            <div
              className="card1"
              onClick={isGroupAllowed ? onlineSubscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.online}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-1"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Online</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? offlineSubscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.offline}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-2"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Offline</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? expiredSubscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.expired}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-3"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Expired</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? holdSubscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.hold}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-4"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>On-Hold</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? inactiveSubscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.inactive}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-5"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Inactive</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? Subscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.online + data.offline}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-5"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Active</h4>
              </div>
            </div>
            <div
              className="card1"
              onClick={isGroupAllowed ? Subscriptions : undefined}
            >
              <div className="card-top">
                <div className="card-left">
                  <h4>
                    {data.online + data.offline + data.expired + data.hold}
                  </h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-5"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Total</h4>
              </div>
            </div>
          </>
        )}
        {group === "OSS" && (
          <>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>
                    {(
                      (data.online /
                        (data.online +
                          data.offline +
                          data.expired +
                          data.hold)) *
                      100
                    ).toFixed(2)}
                    %
                  </h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-1"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Online </h4>
              </div>
            </div>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>
                    {(
                      (data.offline /
                        (data.online +
                          data.offline +
                          data.expired +
                          data.hold)) *
                      100
                    ).toFixed(2)}
                    %
                  </h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-2"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Offline</h4>
              </div>
            </div>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>
                    {(
                      (data.expired /
                        (data.online +
                          data.offline +
                          data.expired +
                          data.hold)) *
                      100
                    ).toFixed(2)}
                    %
                  </h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-3"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Expired </h4>
              </div>
            </div>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>
                    {(
                      (data.hold /
                        (data.online +
                          data.offline +
                          data.expired +
                          data.hold)) *
                      100
                    ).toFixed(2)}
                    %
                  </h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-4"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>On-Hold </h4>
              </div>
            </div>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>{data.inactive}</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-5"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Inactive </h4>
              </div>
            </div>
            <div className="card1">
              <div className="card-top">
                <div className="card-left">
                  <h4>100%</h4>
                </div>
                <div className="card-right">
                  <div className="card-right-inner-1"></div>
                </div>
              </div>
              <div className="card-bottom">
                <hr className="divider" />
                <h4>Total </h4>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="chart">
        {group === "OSS" || group === "yes" ? <ChartOSS /> : <Chart />}
      </div>
    </div>
  );
}

export default Dashboard;
