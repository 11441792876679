import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

function QuotationOrder(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [discount, setDiscount] = useState(0);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0);
  const [totalWithoutDiscount, setTotalWithoutDiscount] = useState(0);
  const history = useHistory();

  const [cat, setCat] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/order/details/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              order_id: props.order_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setCat(response.data);
          const initialTotal = calculateTotal(response.data);
          setTotalWithoutDiscount(initialTotal);
          setTotalWithDiscount(initialTotal);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getOrders();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, props.order_id, user.username]);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      history.push("/accounting/quotation");
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  const calculateTotal = (orderItems) => {
    return orderItems.reduce((total, item) => {
      return (
        total +
        (item.item_type === "Product"
          ? parseFloat(item.product_selling) * item.quantity
          : parseFloat(item.service_selling) * item.quantity)
      );
    }, 0);
  };

  const total = calculateTotal(cat);

  // Store data to be submitted
  const submitData = {
    Action: "Post",
    order: props.order_id,
    total: totalWithoutDiscount,
    discount: discount,
    userdealer: user.username,
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(`${API_URL}/inventory/quotations/`, submitData, {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  let handleDiscountChange = (e) => {
    const discountValue = parseFloat(e.target.value);
    if (!isNaN(discountValue) && discountValue >= 0 && discountValue <= total) {
      setDiscount(discountValue);
      setTotalWithDiscount(total - discountValue);
    }
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <table className="table">
            <thead>
              <tr>
                <th>Item Type</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {cat.map((item, index) => (
                <tr key={index}>
                  <td>{item.item_type}</td>
                  <td>
                    {item.item_type === "Product"
                      ? item.product_name
                      : item.service_name}
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {item.item_type === "Product"
                      ? `$${parseFloat(item.product_selling).toFixed(2)}`
                      : `$${parseFloat(item.service_selling).toFixed(2)}`}
                  </td>
                  <td>
                    {item.item_type === "Product"
                      ? `$${(
                          parseFloat(item.product_selling) * item.quantity
                        ).toFixed(2)}`
                      : `$${(
                          parseFloat(item.service_selling) * item.quantity
                        ).toFixed(2)}`}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" className="text-right">
                  Total:
                </td>
                <td>{`$${total.toFixed(2)}`}</td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">
                  Discount:
                </td>
                <td>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    max={total.toFixed(2)}
                    value={discount}
                    onChange={handleDiscountChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">
                  Total with Discount:
                </td>
                <td>{`$${totalWithDiscount.toFixed(2)}`}</td>
              </tr>
            </tbody>
          </table>

          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Create Quotation
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default QuotationOrder;
