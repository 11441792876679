import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import { Popover, Typography } from "@mui/material";

const colors = ["#E85C0D", "#821131", "#86AB89", "#FFAF00", "#800000"];

const RectangleWithInitials = ({
  initials,
  isFirst,
  isLast,
  isSingle,
  onClick,
}) => {
  const color = colors[initials.charCodeAt(0) % colors.length];

  const rectangleStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: color,
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: isSingle
      ? "4px"
      : isFirst
      ? "4px 0 0 4px"
      : isLast
      ? "0 4px 4px 0"
      : "0",
    marginBottom: "2px",
    cursor: "pointer", // Add pointer cursor to indicate it's clickable
  };

  return (
    <div style={rectangleStyle} onClick={onClick}>
      {initials}
    </div>
  );
};

const Cards = (props) => {
  const [labels, setLabels] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  useEffect(() => {
    let isMounted = true;

    const getLabels = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/labels/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              sub_id: props.sub_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setLabels(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getLabels();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, user.username, props.sub_id]);

  const handleClick = (event, label) => {
    setAnchorEl(event.currentTarget);
    setSelectedLabel(label);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "120px",
  };

  return (
    <div>
      <div style={containerStyle}>
        {labels.map((label, index) => (
          <RectangleWithInitials
            key={index}
            initials={label.label_name[0]}
            isFirst={index === 0}
            isLast={index === labels.length - 1}
            isSingle={labels.length === 1}
            onClick={(event) => handleClick(event, label)}
          />
        ))}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {selectedLabel && (
          <div style={{ padding: "10px" }}>
            <Typography variant="h6">{selectedLabel.label_name}</Typography>
            <Typography variant="body2">
              {selectedLabel.label_description}
            </Typography>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default Cards;
